<template>
    <div>
        <div id="mapX" class="map" style="height: 300px; width: 100%;" />
    </div>
</template>

  
<script>
import Map from 'ol/Map';
import View from 'ol/View';
import {Icon, Style} from 'ol/style.js';

import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';



import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import VectorSource from 'ol/source/Vector.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';


export default
{
    name: 'GeoBlock',
    props: {
        center: Object,
        places: Array
    },
    components: {
    },
    data() {
        return {
            here: { lat: 48.9103066, lon: 2.1803297 },

            zoom: 3,
            map: null
        }
    },
    methods:
    {
        init_geo: function()
        {
            if ("geolocation" in navigator)
            {
                navigator.geolocation.getCurrentPosition(this.refresh_geo);
                //navigator.geolocation.watchPosition(this.refresh_geo);
            }
            else
            {
                console.log("Geo incompatible")
            }

            this.map = new Map({
                target: 'mapX',
                layers: [
                    new TileLayer({
                        source: new XYZ({ url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png' })
                    })
                ],
                view: new View({
                    center: [this.here.lat, this.here.lon],
                    zoom: this.zoom
                })
            });
        },
        refresh_geo: function(pos)
        {
            this.here = { lon: pos.coords.longitude, lat: pos.coords.latitude }

            this.map.getView().setZoom(14);
            this.map.getView().setCenter(fromLonLat([this.here.lon, this.here.lat]));

            this.$emit("refresh", this.here);
        },
        distance: function(coords)
        {
            var lon2 = this.here.lon * Math.PI / 180;
            var lat2 = this.here.lat * Math.PI / 180;
            var lon1 = coords[1] * Math.PI / 180;
            var lat1 = coords[0] * Math.PI / 180;

            var dist = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1))*6371

            if(dist>1) { return dist.toFixed(2) + " km" }
            else { return dist.toFixed(2) * 1000 + " m" }
        },
        load_places: function()
        {

            var feat = [];

            const paris = new Feature({ geometry: new Point(fromLonLat([2.353, 48.8566])) });

            feat.push(paris)

            for(var i=0; i<this.places.length; i++)
            {
                var plc = this.places[i];

                //console.log("Geo" + plc.geo.coordinates[0])

                const iconFeature = new Feature({
                geometry: new Point(fromLonLat([plc.geo.coordinates[1], plc.geo.coordinates[0]])),
                name: plc.alias,
                population: 4000,
                rainfall: 500,
                });

                const iconStyle = new Style({
                image: new Icon({
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: this.api +  'places/icon?type=' + plc.type
                }),
                });

                iconFeature.setStyle(iconStyle);
                feat.push(iconFeature);

                //console.log("Prop(" + plc.alias + "):", iconFeature.getGeometry())
            }

            const vectorSource = new VectorSource({ features: feat });
            const vectorLayer = new VectorLayer({ source: vectorSource });
            
            this.map.addLayer(vectorLayer);
        }


    },
    mounted()
    {
        this.init_geo();
        console.log('*Mount: OLMaps')
        /*
        setInterval(() =>
        {
            this.near_places()
        }, 20000);
        */
    }
}
</script>
  
<style scoped>
#map
{
    width: 100%;
    height: 150px;
}

.position
{
    font-size:  8px;
    font-weight: bold;
}

.back_near
{
    background-color: gainsboro;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><text opacity='0.1' x='50%' y='50%' fill='black' font-family='sans-serif' font-size='20vw' font-weight='bold' dominant-baseline='middle' text-anchor='middle'>Near</text></svg>");
    opacity: 0.9;
    text-align: left;
}

.list_title
{
    font-weight: bold;
    font-size: 11px;
}

.list_desc
{
    font-style: italic;
    font-size: 10px;
    overflow: hidden;
}
</style>