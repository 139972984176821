<template>
    <div class="block">
        <span style="float: left; align-items: center; text-align: center;">
            <div class="month">{{ days[dow] }}</div>
            <div v-if="holiday" class="num" style="text-shadow: 3px 3px 8px #000; color: white;">{{ dd }}</div>
            <div v-else class="num" :style="'color: ' + (color ? color : '#555') + ';'">{{ dd }}</div>
            <div class="month">{{ months[mm] }}</div>
        </span>
        <span style="float: right; height: 100%;">
            <div class="year">{{ year }}</div>
        </span>
    </div>
</template>

<script>

export default {
    name: 'DateBlock',
    props: ['date', 'selected', 'color', 'holiday'],
    data()
    {
        return {
            now: new Date(),
            dow: 0,
            dd: 1,
            mm: 1,
            year: 2020,
            months: ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec'],
            days: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
        };    
    },
    methods:
    {
        parse_date: function(date)
        {
            var DT = new Date(date);
            this.dow = DT.getUTCDay();
            this.dd = DT.getUTCDate();
            this.mm = DT.getUTCMonth();
            this.year = DT.getUTCFullYear();            
        }
    },
    watch:
    {
        date: function(d)
        {
            this.parse_date(d);
        }   
    },
    mounted()
    {
        if(this.date)
        {
            this.parse_date(this.date)
        }
        else
        {
            //this.parse_date(this.now)
            
        }
        
    }
}

</script>

<style scoped>
.block
{
    height: 3rem;
    width: 3rem;

    min-width: 40px;

    margin: 0px;
    padding: 2px;

    border: 2px outset red;
    border-radius: 12px;
    font: bold 1rem sans-serif;
}

.num
{
    font-size: large;
    font-weight: bolder;
    line-height: 1em;
}

.month
{
    font-size: x-small;
    text-align: center;
    align-items: center;
    line-height: 1em;
}

.year
{
    font-size: smaller;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: center;
    background-color: black;
    display: inline-block;
    max-width: 100%;
    color: white;
}
</style>