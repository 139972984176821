<template>
    <div class="square" :style="code ? css : ''">
        <span>
            <span class="lcd hidden-sm-and-down" style="color: red;"> {{ displayed_time(event.start) }} - </span>
            <strong>{{ event.title }}</strong>

            <strong v-if="event.title">{{ event.title }}</strong>
            <strong v-else-if="event.action && event.action.title" style="color: #070"><em>{{ event.action.title.fr }}</em></strong>
            <strong v-else style="color: #DDD"><em>No title</em></strong>

            <!--<small style="color: #AAA; float: right;" class="hidden-sm-and-down">DB:{{ event.database }}</small>-->
        </span>
        <span>
            <br/>
            <em> {{ event.desc }}</em>

            <span v-if="event.place && event.place.alias">
                <small style="color: blue;"><em>@{{ event.place.alias }}</em></small>
            </span>
            <span v-if="event.data && event.data.abstract">
                <small style="color: orange;"><em>*{{ event.data.abstract }}</em></small>
            </span> 
        </span>
                
        <div v-if="event.task && event.task.tasked" class="task">
            <div v-if="event.task.progress == 100" style="float: right; font-style: italic;">
                <el-icon color="#0F0"><Select /></el-icon> Done! <span v-if="event.task.finish"> at {{ displayed_date(event.task.finish) }} </span>
            </div>
            <div v-else>
                <div class="hidden-sm-and-down" style="display:flex; width: 100%;">
                    <span
                        style="margin: 0%; border: 0%; padding: 0px;" >
                        <strong>{{ event.task.priority }}</strong>
                        <el-icon color="#FA8"><Star /></el-icon>        
                    </span>
                    <span
                        style="width: 75%; margin: 0%; border: 0%; padding: 0px;">
                        <el-progress
                            :percentage="parseInt(event.task.progress)"
                            :color="colors"
                            :text-inside="true"
                            :stroke-width="20"
                            style="margin: 0%; border: 0%; padding: 0px;" />
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
//import RatingStarsSelector from '@/components/RatingStarsSelector.vue'

const data =
{
    api: process.env.VUE_APP_API,
    colors:
    [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
    ]
};

export default {
  name: 'DataSquare',
  props: ['event', 'selected'],
  components:
  {
    //RatingStarsSelector
  },
  data() { return data },
  methods:
  {
    displayed_date: function (date)
    {
        var dt = new Date(date);
        var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
        var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
        var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
        return str;
    },
    displayed_time: function (time)
    {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
    },
  },
  computed:
  {
    css: function()
    {
        return "background-image: url('" + this.api + 'action/icon/' + this.code + "');";
    },
    code: function()
    {
        return this.event.code || (this.event.action && (this.event.action.class || this.event.action.code));
    }
  }
}

</script>

<style scoped>
.square
{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: small;
    text-align: left;
    max-width: 250px;
    min-height: 100px;
    max-height: 200px;
    margin: 2px;
    padding: 2px;

    border: 1px outset gainsboro;
    border-radius: 5px;
    box-shadow: 2px 2px;
    
    background-position: center;
    background-color:rgba(255,255,255,1);
    background-size: 32px;
    background-repeat: no-repeat;

    /*display:flexbox*/
    position: relative;

}

@font-face
{
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

.lcd
{
    text-align: left;
    font-size: 8px;
    font-weight: bold;
    font-family: LCD;
}

.task
{
    position: absolute;
    bottom:2px;
    width: 100%;
    
}


</style>