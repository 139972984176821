<template>
    <div style="width: 100%;">
        <!--<el-card shadow="always">-->        
            <div v-if="action">
                <div v-if="error">
                    <h1>Unfound module: {{ error }}</h1>
                </div>
                <div v-else>
                    <!--<strong>Old Metadata: {{ metadata }}</strong>-->
                    <component v-bind:is="component" @metadata="onMetadata" :metadata="old_metadata" :parent_event="parent_event"></component>
                </div>
            </div>
            <div v-else>
                <h1> No action set </h1>
            </div>

    </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue'

import DefaultAction from '../../../actions/default/web.vue'
//import DefaultAction from '../../public/actions/default/web.vue'

export default {
    name: "ActionExec",
    data() {
      return {
        path: '',

        index: 0,

        metadata : {},
        component: null,
        error: null
      }
    },
    components:
    {
        DefaultAction,
    },
    props:
    [
        'action',
        'old_metadata',
        'parent_event'
    ],
    methods:
    {
        set_action: function(action)
        {
            if(action)
            {
                this.component = null;

                //var file = import(`../../public/actions/${action}/web.vue`);
                var file = import(`../../../actions/${action}/web.vue`);

                file
                .then(() => { this.$emit('loaded', true); this.error = null; /*console.log("LOAD COMPONENT: Success", r)*/ })
                .catch((e) => { this.$emit('loaded', false); this.error = e; /*console.log("LOAD COMPONENT: Error", e)*/ })
                
                this.component = markRaw(
                defineAsyncComponent(
                {
                    loader: () => file,
                    loadingComponent: DefaultAction,
                    delay: 200,
                    errorComponent: DefaultAction,
                    timeout: 3000
                }))                
            }
        },
        onMetadata: function(d)
        {
            this.metadata = d;
        }
    },
    watch:
    {
        metadata: function(m)
        {
            //console.log("Film Set:", m)
            this.$emit('new_metadata', m);
        },
        action: function(a)
        {
            //console.log("Modified");
            this.set_action(a)
        },
        old_metadata: function(m)
        {  
            this.metadata = m; //this.old_metadata;
            this.set_action(this.action);
        }
    },
    mounted()
    {
        this.metadata = this.old_metadata;
        this.set_action(this.action);
    }
}
</script>