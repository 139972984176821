<template>
  <div>
    <strong>Places:</strong>
    <div id="mapV" class="map" style="height: 300px; width: 100%;" />


    <el-row v-for="p,i in places" :key="i">
      <el-col :span="24">
        <PlaceLine :place="p" @click="center(p.geo.coordinates[1], p.geo.coordinates[0])"/>
      </el-col>        
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
import PlaceLine from '@/components/PlaceLine.vue'

import Map from 'ol/Map';
import View from 'ol/View';
import {Icon, Style} from 'ol/style.js';

import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';



import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import VectorSource from 'ol/source/Vector.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';


export default {
  name: "PlacesView",
  components: {
    PlaceLine
    //LMap, LTileLayer, LMarker, LIcon
  },
  data () {
    return {
      api: process.env.VUE_APP_API,

      here: { lat: 48.90692, lon: 2.26795},
      map: null,
      places: [],
      plc_edited: null,

      zoom: 13
    };
  },
  methods:
  {
    load_places: function()
    {
      axios.get(process.env.VUE_APP_API + 'places/list', { params: { abc: 'ABC' }, withCredentials: true })
      .then(response =>
      {
        console.log("Reponse of List ***********************************", response)
        this.places = response.data;


        var feat = [];

        const paris = new Feature({
          geometry: new Point(fromLonLat([2.353, 48.8566])),
        });

        feat.push(paris)

        for(var i=0; i<this.places.length; i++)
        {
          var plc = this.places[i];

          //console.log("Geo" + plc.geo.coordinates[0])

          const iconFeature = new Feature({
            geometry: new Point(fromLonLat([plc.geo.coordinates[1], plc.geo.coordinates[0]])),
            name: plc.alias,
            population: 4000,
            rainfall: 500,
          });

          const iconStyle = new Style({
            image: new Icon({
              anchorXUnits: 'fraction',
              anchorYUnits: 'pixels',
              src: this.api +  'places/icon?type=' + plc.type
            }),
          });

          iconFeature.setStyle(iconStyle);
          feat.push(iconFeature);

          //console.log("Prop(" + plc.alias + "):", iconFeature.getGeometry())
        }

        const vectorSource = new VectorSource({ features: feat });
        const vectorLayer = new VectorLayer({ source: vectorSource });
        
        this.map.addLayer(vectorLayer);

      }).catch(console.log("errrr"));
    },
    init_geo: function()
    {
        if ("geolocation" in navigator) {
        /* la géolocalisation est disponible */
            navigator.geolocation.getCurrentPosition(this.refresh_geo);
            //navigator.geolocation.watchPosition(this.refresh_geo);
        } else {
        /* la géolocalisation n'est pas disponible */
            console.log("Geo incompatible")
        }

        this.map = new Map({
            target: 'mapV',
            layers: [
                new TileLayer({
                    source: new XYZ({ url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png' })
                })
            ],
            view: new View({
                center: [this.here.lat, this.here.lon],
                zoom: this.zoom
            })
        });
    },
    refresh_geo: function(pos)
    {
        //console.log("************ Coords:", pos);

        this.here = { lon: pos.coords.longitude, lat: pos.coords.latitude }

        this.map.getView().setZoom(13);
        this.map.getView().setCenter(fromLonLat([this.here.lon, this.here.lat]))

    },
    center: function(lon, lat)
    {
      this.map.getView().setZoom(13);
      this.map.getView().setCenter(fromLonLat([lon, lat]))
    },
    near_places: function()
    {
        //console.log("Here:", this.here)
        axios.post(process.env.VUE_APP_API + 'places/near', { params: { geo: this.here }, withCredentials: true })
        .then(response =>
        {
            //console.log("Reponse of Near ***********************************", response)
            this.near = response.data;
        }).catch(console.log("errrr"));
    },
    distance: function(coords)
    {
        var lon2 = this.here.lon * Math.PI / 180;
        var lat2 = this.here.lat * Math.PI / 180;
        var lon1 = coords[1] * Math.PI / 180;
        var lat1 = coords[0] * Math.PI / 180;

        var dist = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1))*6371

        if(dist>1)
        {
            return dist.toFixed(2) + " km"
        }
        else
        {
            return dist.toFixed(2) * 1000 + " m"
        }
    },
    new_place: function()
    {
        //console.log("DATE:" + date)
        //var dt = new Date()

        this.plc_edited = {
        _id: null,
        name: "",
        alias: "",
        address: { street: '', city: '', country: '', lon: 0, lat: 0 },
        actions: [],
        mode: 0
        }
    }
  },
  mounted()
  {
      this.init_geo();
      this.load_places();
  }
}
</script>

<style scoped>
button
{
    height: 60px;
    width: 60px;
    padding: 1px;
    margin: 1px;
    box-shadow: 3px 3px 2px grey;
    font-family:Arial, Helvetica, sans-serif;
    font-size: small;
    border-color: grey;
}
</style>