<template>
  <div>
    <el-dialog v-model="show" size="100%" top="5%" width="90%" height="90%" direction="rtl" :show-close="false">
      <template #header>
        <div class="my-header">
          <!--<DataLine :place="place" />-->

          <PlaceLine :place="place" />
        </div>
      </template>

      Place: {{ place }}

    <el-tabs class="demo-tabs">
      <el-tab-pane label="Map">
        <div id="map_" class="map"></div>
        <small> {{here.lat}},{{here.lon}}</small>
      </el-tab-pane>
      
      <el-tab-pane label="General">
        <el-form :model="place" label-width="auto" label-position="left" size="small">
          <el-form-item label="Name:">
            <el-input v-model="place.name" />
          </el-form-item>
          <el-form-item label="Alias @:">
            <el-input v-model="place.alias" placeholder="Alias">
              <template #prepend>@</template>
            </el-input>               
          </el-form-item>   
          <el-form-item label="Address:">
            <el-input v-model="place.address.street" />
            <el-input v-model="place.address.city" />
            <el-input v-model="place.address.country" />
          </el-form-item>

          <el-form-item label="Coordinates:">
            <el-row>
              <el-col :span="12">
                <el-input v-model="place.lat" placeholder="Latitude coordinate">
                  <template #prepend>Lat:</template>
                </el-input>
              </el-col>              
              <el-col :span="12">
                <el-input v-model="place.lon" placeholder="Longitude coordinate">
                  <template #prepend>Lon:</template>
                </el-input>                
              </el-col>
            </el-row>
          </el-form-item>
          <el-button :loading="status.position.loading" bg text :type="status.position.color" @click="find_position">
            {{ status.position.text }}
          </el-button>
          <el-button :loading="status.address.loading" bg text :type="status.address.color" @click="find_address">
            {{ status.address.text }}
          </el-button>

        </el-form>
      </el-tab-pane>

      <el-tab-pane label="Type">
        <el-row v-for="r in [0,1,2,3]" :key="r">
          <el-col v-for="c in [0,1,2,3,4,5]" :key="c" :span="4">
            {{ 6 * r + c }} 
            <div v-if="(6*r + c) < types.length" @click="place.type = types[6*r+c].type">
              <el-image width="24" height="24" :src="this.api + '/places/icon?type=' + types[6*r+c].type" fit="cover" />
              <br/>
              
              <strong> {{ types[6*r+c].desc }}</strong>
            </div>
            
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="Misc.">
        <el-form :model="place" label-width="auto" label-position="left" size="small">
          {{ place }}
        </el-form>
      </el-tab-pane>
    </el-tabs>



      Error pack:
      {{ error_pack }}

      <el-affix position="bottom" :offset="20">
        <div style="width: 100%; justify-content: space-between; justify-items: center;">
          <el-button-group style="justify-content: space-between; justify-items: center;">
            <el-button type="default" plain class="button">
              <div>
                <el-icon size="32"><DocumentAdd /></el-icon>
                <div>Copy</div>              
              </div>
            </el-button>
            <el-button v-if="place._id" type="default" plain class="button">
              <div>
                <el-icon size="32"><DocumentRemove /></el-icon>
                <div>Cut</div>              
              </div>
            </el-button>
            <el-button v-if="place._id" type="default" plain class="button">
              <div>
                <el-icon size="32"><Document /></el-icon>
                <div>Paste</div>              
              </div>
            </el-button>   
          </el-button-group>


          <el-button-group>
            <el-button v-if="!place._id" type="primary" plain @click="new_place" class="button">
              <div>
                <el-icon size="32"><Plus /></el-icon>
                <div>Create</div>              
              </div>
            </el-button>
            <el-button v-if="place._id" type="warning" plain @click="edit_place" class="button">
              <div>
                <el-icon size="32"><Refresh /></el-icon>
                <div>Edit</div>              
              </div>
            </el-button>
            <el-button v-if="place._id" type="danger" plain @click="delete_place" class="button">
              <div>
                <el-icon size="32"><Delete /></el-icon>
                <div>Delete</div>              
              </div>
            </el-button>   
          </el-button-group>

        </div>

      </el-affix>

    </el-dialog>
  </div>
</template>

<script>
//import ActionSelector from '../components/ActionSelector.vue'
//import ActionExec from './ActionExec.vue'
//import DataLine from './DataLine.vue'
import PlaceLine from '@/components/PlaceLine.vue'
//import TimeSelector from '@/components/TimeSelector.vue'
import axios from 'axios'


import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';



export default {
    name: 'EditPlace',
    props: ['place_i', 'show_i'],
    emits: ['update:place_i', 'done'],
    components: {
      //ActionSelector,
      //ActionExec,
      //DataLine,
      PlaceLine,
      //TimeSelector
    },
    data() {
      return {
          api: process.env.VUE_APP_API,

          here: { lat: 48.9103066, lon: 2.1803297 },
          near: [],

          zoom: 3,
          map: null,

          place:
          {
            _id: '',
            name: '',
            alias: '',
            address: { street: '', city: '', country: '' },
            geo: { type: 'Point', coordinates: [0, 0] }
          },
          status: {
            position: { color: 'info', text: 'Find position'},
            address: '' },
          error_pack: {},
          show: false,

          types: [
            { type: 'Home', desc: 'Domicile'},
            { type: 'Gym', desc: 'Salle de sport' },

            { type: 'Parc', desc: 'Parc pour enfants' },


            { type: 'Cinema', desc: 'Salle de cinéma' },
            { type: 'Resto', desc: 'Restaurant' },
            { type: 'Shop', desc: 'Magazin, Super/Hypermarché' },
            { type: 'Mall', desc: 'Centre commercial, Mall' },

            { type: 'Garage', desc: 'Garage / Entretien' },

            { type: 'TrainStation', desc: 'Gare de Train' },
            { type: 'Airport', desc: 'Aéroport' },
            { type: 'DentalOffice', desc: 'Cabinet/Centre dentaire' },
            { type: 'MedicalOffice', desc: 'Cabinet/Centre médical' },

            { type: 'Mosque', desc: 'Mosquée' }
          ]
        }
    },
    methods: {
      displayed_date: function (date) {
        var dt = new Date(date);
        var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
        var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
        var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
        return str;
      },
      displayed_time: function (time) {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
      },
      new_place: function()
      {
        axios.post(this.api + 'places/', this.place, { withCredentials: true })
        .then(response =>
        {
          this.error_pack = response.data.error_pack;
          if (!Object.keys(response.data.error_pack).length) { this.finish(); }
        })
        .catch(err =>
        {
          console.log("Error of POST", err)
        });
      },
      edit_place: function()
      {
        // A implementer
        this.place.database = this.place.database || "default";

        axios.put(this.api + 'places/' + this.place._id, this.place, { withCredentials: true })
        .then(response =>
        {
          console.log("Response of PUT", response);
          if(response.error_pack) { this.error_pack = response.data.error_pack; }
          
          //if (!Object.keys(response.data.error_pack).length) { this.finish(); }
        })
        .catch(err => { console.log("Error of PUT", err) })
        .finally(() => this.finish())
      },
      delete_place: function()
      {
        axios.delete(this.api + 'places/' + this.place._id, { withCredentials: true })
        .then(response =>
        {
          console.log("Response of delete", response);
        })
        .catch(err =>
        {
          console.log("Error of DELETE:", err.message);
        })
        .finally(() => this.finish())
      },
      finish() {
        this.show = false;

        this.$emit('done');
      },
      cut: function()
      {

      },
      copy: function()
      {

      },
      paste: function()
      {

      },
      init_geo: function()
      {
        if(!this.map)
        {
          this.map = new Map({
              target: 'map_',
              layers: [
                  new TileLayer({
                      source: new XYZ({ url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png' })
                  })
              ],
              view: new View({
                  center: [this.here.lat, this.here.lon],
                  zoom: this.zoom
              })
          });
        }
      },
      refresh_geo: function(pos)
      {
          //console.log("************ Coords:", pos);
          this.status.position = { color: "success", text: "Localised", loading: false };

          var lat = pos.coords.latitude;
          var lon = pos.coords.longitude;

          this.place.lat = lat;          
          this.place.lon = lon;

          this.place.geo.coordinates = [parseFloat(lat), parseFloat(lon)];

          this.here = { lat: lat, lon: lon }

          this.map.getView().setZoom(13);
          this.map.getView().setCenter(fromLonLat([this.here.lon, this.here.lat]))

          //this.find_address();
      },
      near_places: function()
      {
          //console.log("Here:", this.here)
          axios.post(process.env.VUE_APP_API + 'places/near', { params: { geo: this.here }, withCredentials: true })
          .then(response =>
          {
              //console.log("Reponse of Near ***********************************", response)
              this.near = response.data;
          }).catch(console.log("errrr"));
      },
      distance: function(coords)
      {
          var lon2 = this.here.lon * Math.PI / 180;
          var lat2 = this.here.lat * Math.PI / 180;
          var lon1 = coords[1] * Math.PI / 180;
          var lat1 = coords[0] * Math.PI / 180;

          var dist = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1))*6371

          if(dist>1)
          {
              return dist.toFixed(2) + " km"
          }
          else
          {
              return dist.toFixed(2) * 1000 + " m"
          }
      },
      find_position: function()
      {
        if ("geolocation" in navigator)
        {
          this.status.position = { color: "warning", text: "Search...", loading: true };
          navigator.geolocation.getCurrentPosition(this.refresh_geo);
            //navigator.geolocation.watchPosition(this.refresh_geo);
        }
        else
        {
          this.status.position = { color: "danger", text: "Not compatible!", loading: false };
        }
      },
      find_address: function()
      {
        this.status.address = { color: "warning", text: "Search...", loading: true };
        axios.get('https://api-adresse.data.gouv.fr/reverse/', { params: { lon: this.place.lon, lat: this.place.lat }, withCredentials: false })
        .then(response =>
        {
          var adr = response.data.features[0].properties

          if(adr)
          {
            // Found in France
            this.status.address = { color: "success", text: "Found", loading: false };

            this.place.address = 
            {
              city: adr.city,
              street: adr.name,
              country: 'FR'
            }           
          }
          else
          {
            // Unfound in France
            this.status.address = { color: "warning", text: "Not in France", loading: false };
          }

        })
        .catch((e) =>
        {
          console.log("ERROR28: " + e);
          this.status.position = { color: "danger", text: "API Error", loading: false };
        });
      }
    },
    computed:
    {
      displayed_date2: function()
      {
        var st = new Date(this.place.start);
        var nd = new Date(this.place.end);

        if(st.getUTCFullYear() === nd.getUTCFullYear())
        {
          if(st.getUTCMonth() === nd.getUTCMonth())
          {
            if(st.getUTCDate() === nd.getUTCDate())
            {
              return 'Day:' + st.getUTCDate() + '/' + (st.getUTCMonth()+1) + '/' + st.getUTCFullYear()
            }
            else
            {
              return st.getUTCDate() + ' -> ' + nd.getUTCDate() + '/' + (nd.getUTCMonth()+1) + '/' + nd.getUTCFullYear();
            }
          }
          else
          {
            return st.getUTCDate() + '/' + (st.getUTCMonth()+1) + ' -> ' + nd.getUTCDate() + '/' + (nd.getUTCMonth()+1) + '/' + nd.getUTCFullYear();
          }
        }
        else
        {
          return st.getUTCDate() + '/' + (st.getUTCMonth()+1) + '/' + st.getUTCFullYear() + ' -> ' + nd.getUTCDate() + '/' + (nd.getUTCMonth()+1) + '/' + nd.getUTCFullYear();
        }      
      },
      displayed_time2: function()
      {
        var st = new Date(this.place.start);
        var nd = new Date(this.place.end);

        var str = (st.getUTCHours() > 9 ? '' : '0') + st.getUTCHours() + ':' + (st.getUTCMinutes() > 9 ? '' : '0') + st.getUTCMinutes();

        if(st.getUTCHours() != nd.getUTCHours() || st.getUTCMinutes() != nd.getUTCMinutes())
        {
          str += ' > ' + (nd.getUTCHours() > 9 ? '' : '0') + nd.getUTCHours() + ':' + (nd.getUTCMinutes() > 9 ? '' : '0') +  nd.getUTCMinutes();
        }
        return str;
      }
    },
    watch:
    {
      place_i: function(new_place)
      {
        console.log("ABC...New place:", new_place)

        if(new_place)
        {
          this.$emit('update:place_i', new_place)
          this.place = new_place;
          this.show = true;

          this.periods = { start: this.place.start, end: this.place.end };
          //console.log("Period set: ", this.periods)
        }
        else
        {
          this.show = false;
        }
      },
      show_i: function(s)
      {
        this.show = s;
      }
    },
    mounted()
    {
      if(this.place && this.place._id)
      {
        axios.get(process.env.VUE_APP_API + 'place/search', { params: { id: this.place._id }, withCredentials: true })
        .then(response =>
        {
            console.log("*** PLACE SEARCH:", response.data[0])
            //this.places = response.data;
            this.place = response.data[0]
        })
        .catch((e) => console.log("*** Place search err:" + e))        
      }
    },
    updated()
    {
      console.log("Updated:")
      this.init_geo();
      // PAtch place ## temporaire
      //if(!this.place.task) { this.place.task = { tasked: false, progress: 0, priority: 0, finish: null }}
    },
}
</script>

<style scoped>

.my-header
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.button
{
  width: 50px;
  height: 50px;
  border: 1px dotted;
  font-size: smaller;
  font-weight: bold;
  box-shadow: 1px 1px;
  display: flex;
}

.bar
{
  display: inline-flex;
  justify-content: stretch;
}


#map_
{
  height: 200px;
  width: 100%;
}







</style>