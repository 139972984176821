<template>
    <div>
        <div v-if="user">
            <p>User logged:</p>

            <h2> User info: </h2>

            <el-descriptions>
                <el-descriptions-item label="Id:">{{ user._id }}</el-descriptions-item>
                <el-descriptions-item label="Name:">{{ user.lastname }} {{ user.firstname }}</el-descriptions-item>
                <el-descriptions-item label="email:">{{ user.email }}</el-descriptions-item>
                <el-descriptions-item label="Intl:">
                    <el-image :src="'https://flagsapi.com/'+user.country+'/shiny/16.png'" />
                    Country: {{ user.country }} -
                    Lang: {{ user.lang }}
                </el-descriptions-item>
                <el-descriptions-item label="Groups:">
                    <el-tag size="small" v-for="g,i in user.groups" :key="i">{{ g.name }}</el-tag>
                </el-descriptions-item>
            </el-descriptions>

            <h2> Connection info: </h2>
            <ol>
                Devices:
                <li v-for="d,i in user.devices" :key="i">
                    Name:{{ d.device.name }} - UUID:{{ d.uuid }} - Logged: {{ d.login.last }}
                </li>
            </ol>

            <el-button @click="logout">LogOut</el-button>
        </div>
        <div v-else>
            <p>No user logged</p>

            <el-form status-icon label-width="120px">
                <el-form-item label="Email:" prop="pass" :error="error_pack.email" >
                    <el-input v-model="email" type="password" autocomplete="off" />
                </el-form-item>
                <el-form-item label="Password:" prop="checkPass" :error="error_pack.password" >
                    <el-input v-model="password" type="password" autocomplete="off" />
                </el-form-item>
            </el-form>

            <el-button @click="login">LogIn</el-button>
        </div>
    <!--
        <b-card no-body class="bar">
            <div v-if="user && Object.keys(user).length">
                <b-row clas="left" no-gutters>
                    <b-col cols="5">
                        <strong></strong>
                        <hr/>
                        <b-button class="button" variant="outline-danger" @click="logout()">
                            <b-icon icon="x-circle" font-scale="1" style="width: 24px; height: 24px;"></b-icon>
                            <br />
                            <strong>LogOut</strong>
                        </b-button>
                    </b-col>
                    <b-col cols="7">
                        <strong class="left">Logged:</strong>
                        <hr/>
                        <strong variant="danger">{{user.lastname}} {{user.firstnamename}}</strong>
                    </b-col>                    
                </b-row>
            </div>
            <div v-else>
                <b-row class="left" no-gutters>
                    <b-col cols="4">
                        <strong>...</strong>
                        <hr/>
                        <b-button class="button" variant="outline-primary" @click="login()">
                            <b-icon icon="key" font-scale="1" style="width: 24px; height: 24px;"></b-icon>
                            <br />
                            <strong>LogIn</strong>
                        </b-button>
            
                        <b-button class="button" variant="outline-success" @click="signin()">
                            <b-icon icon="person-circle" font-scale="1" style="width: 24px; height: 24px;"></b-icon>
                            <br />
                            <strong>SignIn</strong>
                        </b-button>
                    </b-col>
                    <b-col cols="8">
                        <strong class="left">Not Logged:</strong>
                        <hr/>
                        <b-form-group class="mb-0">
                            <b-form-group class="mini" :description="error_pack.email">
                                <b-form-input v-model="email" placeholder="em@il" :state="error_pack.email ? false : null" type="email"></b-form-input>
                            </b-form-group>
            
                            <b-form-group class="mini" :description="error_pack.password">
                                <b-form-input v-model="password" placeholder="PassWorD" :state="error_pack.password ? false : null" type="password"></b-form-input>
                            </b-form-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    -->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ConnectBlock',
    props: {
        msg: String
    },
    data: function() {
        return {
            email: '',
            password: '',

            error_pack: {},
            user: {}
        }
    },
    methods:
    {
        finish: function()
        {
            sessionStorage.setItem('myCat', 'Tom');
            console.log("LOGGED!");
        },
        check_log: function()
        {
            axios.get(process.env.VUE_APP_API, { withCredentials: true })
            .then(response =>
            {
                console.log("Check at /", response);
                this.user = response.data.user;
                this.$emit("update:user", this.user);

                var databases = [{
                        name: 'Default',
                        id: 'default',
                        color: '#000',
                        read: true,
                        write: false,
                        actived: true
                    }]

                // SetDatabase:
                if(this.user)
                {
                    databases.push({
                        name: 'Main DB',
                        id: this.user._id,
                        color: '#A0A',
                        read: true,
                        write: true,
                        actived: true
                    });

                    this.user.groups.forEach(group => { databases.push(group) });
                }

                global.user = this.user;
                global.databases = databases;
            })
            .catch(function(err)
            {
                console.log("Error of post", err);
            });
        },
        login: function()
        {
            var cred = { email: this.email, pass: this.password }
            axios.post(process.env.VUE_APP_API + 'user/login/', cred, { withCredentials: true })
            .then(response =>
            {
                console.log("Response of post", response);

                this.error_pack = response.data.error_pack;
                if (!Object.keys(response.data.error_pack).length) { this.check_log(); }
            })
            .catch(response =>
            {
                console.log("Error of post", response);
            });
        },
        logout: function() {
            console.log('.env:' + process.env.VUE_APP_API)
            axios.get(process.env.VUE_APP_API + 'user/logout', { params: { device: -1 }, withCredentials: true })
            .then(response => {
                console.log("Response of get:......", response);
                //this.user = response.data.user;

                //this.error_pack = response.data.error_pack;
                //if (!Object.keys(response.data.error_pack).length) { this.check_log(); }
                //this.user = null;

                this.check_log();
            })
            .catch(e => { console.log("ERR43: ", e); });
        }
    },
    mounted() {
        this.check_log();
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>