<template>
  <div id="app" class="no_space">
    <el-header style="height: auto;">
      <HeaderBar @help="help_me" />
      <Popup :error_pack="{ test: 'test'}" />
    </el-header>
    <el-container style="height: 100%;">
      <el-aside width="220px" class="hidden-sm-and-down">
        <SideBar :help="help" class="no_space" />
      </el-aside>
      <el-container class="no_space">
        <el-main class="no_space">
          <router-view @help="help_me" class="no_space"/>
        </el-main>
        <el-footer>
          <em>2023 - Organize It</em>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderBar from '../src/components/HeaderBar.vue'
import SideBar from '../src/components/SideBar.vue'
import Popup from './components/Popup.vue'

export default {
  name: 'App',
  components: {
    HeaderBar,
    SideBar,
    Popup
  },
  data()
  {
    return {
      help: {}
    }
  },
  methods:
  {
    help_me: function(help_obj)
    {
      //console.log("Help - Me:", help_obj)
      this.help = help_obj;
    }
  }
}
</script>

<style>
#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: antiquewhite;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*margin-top: 60px*/;

  align-items: center;
  align-content: center;

}


.no_space
{
  padding: 0px;
  border: 0px;
  margin: 0px;
  background-color: ghostwhite;

  min-height: 400px;
  height: fit-content;

}

el-badge
{
  margin-top: 10px;
  margin-right: 40px;
}
</style>
