<template>
  <div class="box">
    <h1>Organize IT <small>v 0.042 </small></h1>
    

    <el-tabs class="demo-tabs">
      <el-tab-pane label="Welcome" name="Welcome">
        <el-row>
          <el-col :span="24" class="line">
            <LinkButton name="Welcome" icon="fa-rocket" link="/" />
            <LinkButton name="Debug" icon="fa-rocket" link="/debug" />
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Calendar" name="Calendar">
        <div style="display: inline-flex; width: 100%; height: auto;">
          <div>
            <LinkButton name="Next" icon="fa-list-ol" link="/next" />
            <LinkButton name="Week" icon="fa-calendar-week" link="/week" />
            <LinkButton name="Month" icon="fa-calendar-days" link="/month" />
          </div>
          
          <div class="widget">
            <TimeBlock /> 
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Tasks" name="Tasks">
        <el-row>
          <el-col :span="24">
            <LinkButton name="Grid" icon="fa-table-cells" link="/tasks" />
            <LinkButton name="Next" icon="fa-clock" link="/tasks/next"/>
            <LinkButton name="Near" icon="fa-compass" link="/tasks/near"/>
            <LinkButton name="Projects" icon="fa-diagram-project" link="/projects"/>        
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="Places" name="Place">
        <div style="display: inline-flex; width: 100%;">
          <div>
            <LinkButton name="Place" icon="fa-map-location" link="/places" @mouseover="$emit('help', { title: 'Your places listing', desc: 'Navigate between your favorites place' })" />

            <ClickButton name="Add" icon="fa-location-crosshairs" @click="new_place" @mouseover="$emit('help', { title: 'Add a new place', desc: 'Creater a new Place with your actual position if avaiable, the new created Place will be accessible by his alias' })" />
          </div>
          
          <div class="widget">
            <GeoBlock @refresh="update_pos" /> 
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Actions" name="Actions">
        <el-row>
          <el-col :span="24" class="line">
            <LinkButton name="Search" icon="fa-star" link="/actions"/>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="User" name="Login">
        <el-row>
          <el-col :span="24" class="line">
            <LinkButton name="LogIn" icon="fa-user-tag" link="/login" />
            <LinkButton name="SignIn" icon="fa-user-plus" link="/signin" />

            <LinkButton name="Settings" icon="fa-user-gear" link="/settings" />
          </el-col>
        </el-row>

      </el-tab-pane>
      
      
    </el-tabs>

    <EditPlace :place_i="plc_edited" @done="ClosePlaceModal"/>
  </div>
</template>


<script>
//import ConnectBlock from '../components/ConnectBlock.vue'
//import GeoBlock from '../components/GeoBlock.vue'
//import PersonBlock from '../components/PersonBlock.vue'
import TimeBlock from '@/components/TimeBlock.vue';
import GeoBlock from '@/components/GeoBlock.vue';
import LinkButton from '@/components/LinkButton.vue';
import ClickButton from '@/components/ClickButton.vue';

import EditPlace from '@/components/EditPlace.vue';

import { ElNotification as notify } from 'element-plus'


//import LinkButton from '../components/LinkButton.vue'

export default {
    name: 'HeaderBar',
    props: {
        
    },
    components: {
      //ConnectBlock,
      //PersonBlock,
      LinkButton, ClickButton,
      TimeBlock,
      GeoBlock,

      EditPlace
    },
    data() {
        return {
          activeIndex: 1,
          here: { lat: 0, lon: 0},
          plc_edited: null
        }
    },
    methods: {
      onBack: function()
      {
        notify('Back');
      },
      update_pos: function(pos)
      {
        this.here = pos;
        //console.log("Update position:", pos)
      },
      new_place: function()
      {
        console.log("ABCDEF")
        this.plc_edited =
        {
          _id: null,
          name: "",
          alias: "",
          address: { street: '', city: '', country: '', lon: this.here.lon, lat: this.here.lat },
          geo: { type: "Point", coordinates: [this.here.lon, this.here.lat]},
          actions: [],
          mode: 0
        }
      },
      ClosePlaceModal: function()
      {
        this.plc_edited = null;
      }
    },
    mounted: function() {
      //console.log("Configured Vue API: " + process.env.VUE_APP_API)
      //console.log("Configured Version Server: " + process.env.VERSION)
    }

}
</script>


<style scoped>
.box
{
  width: 100%;
  background-color: white;
  color: black;
  border: 1px solid;
  border-radius: 10px;
}

.line
{
  display: inline-flex;
  background-color: aliceblue;
}

/*
.demo-tabs > .el-tabs__content
{
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
*/

.widget
{
  background-color: white;

  height: auto;
  width: 100%;
}

.no_space
{
  padding: 0px;
  border: 0px;
  margin: 0px;
  background-color:cornsilk;
}

</style>