<template>
    <div>
        <strong>Settings</strong>

        {{ settings }}

        <el-form :model="setttings" label-width="120px">

            <el-form-item label="Language:"
            @mouseover="$emit('help',
                {
                    title: 'Language',
                    desc: 'Select the the web and application language (default: english)'
                })">
                <el-select v-model="settings.languague" placeholder="please select your languague">
                    <el-option label="French" value="french" />
                    <el-option label="English" value="english" />
                </el-select>
            </el-form-item>

            <el-form-item label="Help side bar"
                @mouseover="$emit('help',
                {
                    title: 'Help side bar',
                    desc: 'Select if you want a lateral help bar for desktop screen (disabled on mobile screens)'
                })">
                <el-switch v-model="settings.helpbar" />
            </el-form-item>

            <el-form-item label="Theme">
                <el-select v-model="settings.theme" placeholder="Color theme selection">
                    <el-option label="Mint" value="mint" />
                    <el-option label="Default" value="default" />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submit">Create</el-button>
                <el-button>Cancel</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

export default {
    name: 'SettingsView',
    components: {

    },
    data()
    {
        return {
            settings: {}
        }
    },
    methods:
    {
        submit: function()
        {
            console.log("Submit settings")
            global.settings = this.settings;
        }
    },
    watch:
    {
        settings: function()
        {
            console.log("SETTi", this.settings)
        }
    },
    props: []
}
</script>

<style scoped>
h1 {
    margin: 40px 0 0;
    color: #FF0;
}
</style>