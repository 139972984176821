<template>
    <div>
        <el-row style="width: 100%; height:100%;">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-loading="load.position">
                <div id="map" class="map"></div>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-loading="load.nearest">
                <div class="back_near">
                    <div class="position">{{ here.lat }} {{ here.lon }}</div>
                    <span v-for="n,i in near" :key="i">
                        <span v-if="n.geo" class="list_desc">{{distance(n.geo.coordinates)}}</span>
                        -
                        <!--<el-img square width="16" height="16" :src="'/places/icons/' + n.type + '.png'"> </el-img>--> 
                        <span class="list_title">{{n.name}}</span>

                    </span>     
                    <el-button @click="near_places();">Refresh</el-button>
                </div>
            </el-col>            
        </el-row>
    </div>
</template>

  
<script>
import axios from 'axios';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';

export default
{
    name: 'GeoBlock',
    props: {
        param: String
    },
    components: {
    },
    data() {
        return {
            here: { lat: 48.9103066, lon: 2.1803297 },
            near: [],

            zoom: 3,
            map: null,

            load: { position: false, nearest: false }
        }
    },
    methods:
    {
        init_geo: function()
        {
            if ("geolocation" in navigator) {
            /* la géolocalisation est disponible */
                navigator.geolocation.getCurrentPosition(this.refresh_geo);
                //navigator.geolocation.watchPosition(this.refresh_geo);
            } else {
            /* la géolocalisation n'est pas disponible */
                console.log("Geo incompatible")
            }

            this.map = new Map({
                target: 'map',
                layers: [
                    new TileLayer({
                        source: new XYZ({ url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png' })
                    })
                ],
                view: new View({
                    center: [this.here.lat, this.here.lon],
                    zoom: this.zoom
                })
            });
        },
        refresh_geo: function(pos)
        {
            this.near_places();

            this.here = { lon: pos.coords.longitude, lat: pos.coords.latitude }

            this.map.getView().setZoom(14);
            this.map.getView().setCenter(fromLonLat([this.here.lon, this.here.lat]))

            this.$emit("refresh", this.here);
        },
        near_places: function()
        {
            this.load.nearest = true;
            axios.post(process.env.VUE_APP_API + 'places/near', { params: { geo: this.here }, withCredentials: true })
            .then(response => { this.near = response.data; })
            .catch((e) => console.log("errrr:" + e))
            .finally(() => this.load.nearest = false);
        },
        distance: function(coords)
        {
            var lon2 = this.here.lon * Math.PI / 180;
            var lat2 = this.here.lat * Math.PI / 180;
            var lon1 = coords[1] * Math.PI / 180;
            var lat1 = coords[0] * Math.PI / 180;

            var dist = Math.acos(Math.sin(lat1)*Math.sin(lat2)+Math.cos(lat1)*Math.cos(lat2)*Math.cos(lon2-lon1))*6371

            if(dist>1) { return dist.toFixed(2) + " km" }
            else { return dist.toFixed(2) * 1000 + " m" }
        }
    },
    mounted()
    {
        this.init_geo();
        /*
        setInterval(() =>
        {
            this.near_places()
        }, 20000);
        */
    }
}
</script>
  
<style scoped>
#map
{
    width: 100%;
    height: 150px;
}

.position
{
    font-size:  8px;
    font-weight: bold;
}

.back_near
{
    background-color: gainsboro;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><text opacity='0.1' x='50%' y='50%' fill='black' font-family='sans-serif' font-size='20vw' font-weight='bold' dominant-baseline='middle' text-anchor='middle'>Near</text></svg>");
    opacity: 0.9;
    text-align: left;
}

.list_title
{
    font-weight: bold;
    font-size: 11px;
}

.list_desc
{
    font-style: italic;
    font-size: 10px;
    overflow: hidden;
}
</style>