import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import { createRouter, createWebHistory } from "vue-router"



import Welcome from '../src/views/WelcomeView.vue'
import Month from '../src/views/MonthView.vue'
import Week from '../src/views/WeekView.vue'
import Next from '../src/views/NextView.vue'
import NextTasks from '../src/views/NextTasksView.vue'
import NearTasks from '../src/views/NearTasksView.vue'

import Actions from '../src/views/ActionsView.vue'
import Tasks from '../src/views/TasksView.vue'
import Projects from '../src/views/ProjectsView.vue'

import Places from '../src/views/PlacesView.vue'
import Settings from '../src/views/SettingsView.vue'
import About from '../src/views/AboutView.vue'
import Debug from '../src/views/DebugView.vue'

import Login from '../src/views/LoginView.vue'
import Signin from '../src/views/SignInView.vue'


const routes = [
  { path: '/', name: 'Welcome', component: Welcome },
  { path: '/about', name: 'About', component: About },
  { path: '/next', name: 'Next', component: Next },
  { path: '/month', name: 'Month', component: Month },
  { path: '/week', name: 'Week', component: Week },
  { path: '/actions', name: 'Action', component: Actions },

  { path: '/projects', name: 'Projects', component: Projects },

  { path: '/tasks', name: 'Tasks', component: Tasks },
  { path: '/tasks/next', name: 'NextTasks', component: NextTasks },
  { path: '/tasks/near', name: 'NearTasks', component: NearTasks },

  { path: '/places', name: 'Places', component: Places },
  { path: '/settings', name: 'Settings', component: Settings },
  { path: '/debug', name: 'Debug', component: Debug },
  { path: '/login', name: 'Login', component: Login },
  { path: '/signin', name: 'SignIn', component: Signin }

]

const router = createRouter({ history : createWebHistory(), routes })
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(ElementPlus)
app.use(router).mount('#app')