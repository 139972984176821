<template>
    <div>
        <el-row>
            <ActionSelector v-model="code" @selected="set_action" />
        </el-row>

        <el-descriptions class="margin-top" title="Stats" :column="3" >
            <el-descriptions-item label="Code:">
                <el-icon>
                    <el-image v-if="action.code" style="width: 16px; height: 16px" :src="api + 'action/icon/' + action.code" fit="cover" />
                </el-icon>
                {{ action.code }}
            </el-descriptions-item>
            <el-descriptions-item label="Stat count:" v-if="action.count">{{ action.count.usage }}</el-descriptions-item>
            <el-descriptions-item label="Return count:">{{events.length}}</el-descriptions-item>
            <el-descriptions-item v-if="action.title" label="Title">
                {{ action.title.fr }} - {{ action.title.en }}
            </el-descriptions-item>
            <el-descriptions-item v-if="action.desc" label="Description">
                {{ action.desc.fr }} - {{ action.desc.en }}
            </el-descriptions-item>
        </el-descriptions>

        <div class="dataline">
            <span v-for="evt,i in events" :key="i" style="display:flex;">
                <DateBlock :date="evt.start" />

                <DataLine :event="evt" @click="ModalEdit(evt)"/>
            </span>
        </div>

        <EditEvent :event_i="evt_edited" :show_i="show_modal" @done="ModalClose()" />
    </div>
</template>

<script>
import axios from 'axios'
import ActionSelector from '@/components/ActionSelector.vue';
import DataLine from '@/components/DataLine.vue';
import DateBlock from '@/components/DateBlock.vue';

import EditEvent from '@/components/EditEvent.vue';


const data = {
        api: process.env.VUE_APP_API,
        code: 'SRS',
        now: new Date(),

        range: { start: null, end: null },
        action: {},
        events: [],
        evt_edited: {},
        show_modal: false

    };

export default {
    name: 'ActionsView',
    components: { ActionSelector, DateBlock, DataLine,  EditEvent },
    data() {
        return data;
    },
    methods:
    {
        set_action: function(action)
        {
            if(action) // if !action >> refresh
            {
                this.action = action;
            }

            axios.get(process.env.VUE_APP_API + 'events/search', { params: { code: action.code }, withCredentials: true })
            .then(response => { this.events = response.data; })
            .catch(e => console.log("ERR33: " + e));
        },
        set_code: function(code)
        {
            axios.get(process.env.VUE_APP_API + 'action/search', { params: { code: code }, withCredentials: true })
            .then(response =>
            {
                this.action = response;

                axios.get(process.env.VUE_APP_API + 'events/search', { params: { code: code }, withCredentials: true })
                .then(response => { console.log('******', response.data); this.events = response.data || []; })
                .catch(e => console.log("ERR25: " + e));
            })
            .catch(e => console.log("ERR99: " + e));
        },
        ModalEdit: function(event)
        {
            //console.log("OPEN MODAL...")
            this.show_modal = true;
            this.evt_edited = event;
        },
        ModalClose: function()
        {
            //console.log("CLOSE MODAL...")
            this.show_modal = false;
            this.evt_edited = null;
            this.set_action(); // Refresh
        }
    },
    mounted: function()
    {
        this.set_code(this.code)
    }
}
</script>

<style scoped lang="css">
@font-face {
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

.link {
    font-family: 'Arial';
}

.lcd {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: smaller;
    
    font-family: LCD;
}

.dataline
{
    background-color: white;
    border-bottom: 1px solid;
    box-shadow: 1px 1px;
    border-radius: 10px;
    margin: 3px;
    padding: 1px;
}
</style>