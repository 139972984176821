<template>
    <div class="side_bar">
        <h1>
            Help informations
            <font-awesome-icon icon="circle-info" />
        </h1>
        <hr/>

        <div class="_title">{{ title }}</div>
        <br/>
        <div class="_desc">{{ desc }}</div>

    </div>
</template>

<script>
//import LinkButton from '../components/LinkButton.vue'


export default
{
    name: 'SideBar',
    props: {
        help: Object
    },
    components: {

    },
    data() {
        return {
        title: '',
        desc: ''

        }
    },
    methods:
    {
        help_me: function(help)
        {
            //console.log("BBBBBBB", )
            this.title = help.title;
            this.desc = help.desc; 
        }
    },
    watch:
    {
        help: function(a)
        {
            //console.log("AAAAAAAAAAAAAAA:"  + a)
            this.help_me(a)
        }
    },
    mounted: function()
    {

    }

}
</script>


<style scoped>
.side_bar
{
  padding: 0%;
  margin: 0%;
  border: 0%;
}

._title
{
  font-weight: bold;
  font-size: 15px;
  margin: 0%;
  padding: 0%;
  border: 0%;
}

._desc
{
  font-size: 12px;
  font-style: oblique;
  margin: 0%;
  padding: 0%;
  border: 0%;
}
</style>
