<template>
    <div>
      <h1>Near tasks</h1>

        <Maps />
    </div>
</template>
  
  
<script>
  //var _events = require('@/lib/events');

  import Maps from '../atomic/OLMap'

    //import DataLine from '../components/DataLine.vue'
    //import EditEvent from '../components/EditEvent.vue';
  
    //import DateBlock from '@/components/DateBlock.vue';
  
    const
      data = {
        now: new Date(),
        show: false,
        database: null,
        tasks: [],
    
        cursor: {},
        evt_edited: null,
        plc_edited: null,
      };
  
    export default {
      name: 'NextView',
      components:
      {
        //EditEvent,
        //DataLine,
        //DateBlock,
        //TimeBar,

        Maps
      },
      data()
      {
        return data;
      },
      methods:
      {


      },
      mounted()
      {
        console.log("NearTasksView");
      }
    }
  </script>
  
  <style scoped lang="css">
    @font-face {
      font-family: 'LCD';
      src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
      /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
    }
  
    .today
    {
      background-color: #AFA;
      border: 1px solid;
      box-shadow: 2px 2px;
      border-radius: 10px;
      margin: 3px;
      padding: 1px;
    }
  
    .other
    {
      background-color: white;
      border-bottom: 1px solid;
      box-shadow: 1px 1px;
      border-radius: 10px;
      margin: 3px;
      padding: 1px;
    }
  </style>