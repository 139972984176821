<template>
    <div class="line">
        <font-awesome-icon icon="star-and-crescent" />
        <span v-for="n,t in times" :key="t">
            <small class="hidden-sm-and-down"><em>{{ t }}</em>:</small>
            <span class="lcd">{{ n }}</span> - 
        </span>
    </div>
</template>
  
<script>
//import TimeBar from '../components/TimeBar.vue'
import axios from "axios"

export default {
    name: 'DailyInfo',
    props: {
        day: Object,
        active: Boolean
    },
    components: {
    //TimeBar
    },
    data() {
        return {
            api: process.env.VUE_APP_API,
            times: { ismak: "", fajr: "", dhuhur: "", asr: "", maghreb: "", isha: ""},
            dt_str: ""

        }
    },
    methods: {
        Time: function (dttm)
        {
            var st = new Date(dttm)
            return ((st.getUTCHours() < 10) ? '0' : '') + st.getUTCHours() + ':' + ((st.getUTCMinutes() < 10) ? '0' : '') + st.getUTCMinutes();
        },
        Get_Times: function()
        {
            var d = new Date(this.day)
            var pos = { lat: 48.9069188, lon: 2.2679456}
            


            this.dt_str = ((d.getUTCDate() < 10) ? '0' : '') + d.getUTCDate() + '-' + (((d.getUTCMonth()+1) < 10) ? '0' : '') + (d.getUTCMonth()+1) + '-' + d.getUTCFullYear();
            var adhan_api = 'https://api.aladhan.com/v1/timings/' + this.dt_str;
            //console.log("API...........:" + adhan_api)
            axios.get(adhan_api, { params: { latitude: pos.lat, longitude: pos.lon, method: 2 },  withCredentials: false })
            .then(response =>
            {
                //console.log("REsponse", response.data.data)

                if(response.data)
                {
                    var tm = response.data.data.timings;
                    this.times =
                    {
                        fajr: tm.Fajr,
                        imsak: tm.Imsak,
                        dhor: tm.Dhuhr,
                        asr: tm.Asr,
                        maghreb: tm.Maghrib,
                        icha: tm.Isha
                    }
                    
                }
            }).catch(e => console.log("Err: Next Evt", e));
        }
    },
    mounted()
    {
        this.Get_Times();
        console.log("Prayer for: ", this.day)
    }
}
</script>

<style scoped>
  @font-face {
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }

  .line
  {
    width: 100%;
    background-color: black;
    color: white;
    border: 2px solid;
    border-radius: 5px;
    border-color: aliceblue;
  }

  .lcd {
    
    font-size: 10px;
    font-weight: bold;
    font-family: LCD;
    color: yellow;

  }
</style>