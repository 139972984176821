<template>
  <div>
    <el-dialog v-model="show" size="100%" top="5%" width="85%" height="90%" direction="rtl" :show-close="false" :before-close="cancel">
      <template #header>
        <div class="my-header">
          <DataLine :event="event" :color="db_colorize(event.database)" />
        </div>
      </template>




    <div class="demo-collapse">
      <el-collapse accordion>
        <el-collapse-item name="global">
          <template #title>
            <font-awesome-icon icon="bolt" />
            <h3>Global:</h3>

            <div>
              <strong>Global:</strong>
              <small v-if="event.title" class="info">
                <font-awesome-icon icon="heading" />
                <strong> - {{ event.title }} </strong>
                <!--<em v-if="event.desc"> {{ event.desc }} </em> -->
              </small>
              <small class="info">
                <font-awesome-icon icon="calendar-day" />
                <strong> - {{displayed_date}}</strong>
              </small> -
              <small class="info">
                <font-awesome-icon icon="clock" />
                <strong> - {{displayed_time}}</strong>
              </small>
            </div>
          </template>

          <FormWidget title="Title:" :error="error_pack.title">
            <el-input v-model="event.title" :placeholder="event?.action?.title?.fr || 'No title'" />
          </FormWidget>

          <FormWidget title="Description:" :error="error_pack.desc">
            <el-input v-model="event.desc" :placeholder="event?.action?.desc?.fr || 'No desc'" type="textarea" />
          </FormWidget>

          <!--
          <FormWidget title="Mode:" color="#FFA">
            <el-select v-model="event.mode" placeholder="Category">
              <el-option label="1.Perso" value="0" />
              <el-option label="2.Pro" value="1" />
              <el-option label="3.Assoc" value="2" />
              <el-option label="4.Relig" value="3" />
            </el-select>
          </FormWidget>
          -->

          <FormWidget title="Dates:">
            <el-date-picker v-model="event.start" type="datetime" placeholder="Select date and time" />  
            <el-date-picker v-model="event.end" type="datetime" placeholder="Select date and time" />  
          </FormWidget>

          <FormWidget title="Times:">
            <TimeSelector v-model="periods" />
              <!-- {{ periods }} -->
          </FormWidget>

        </el-collapse-item>
        <el-collapse-item name="action">
          <template #title>
            <font-awesome-icon icon="star" />
            <h3>Action:</h3>

            <strong>Action:</strong>
            <small v-if="event.code" class="info">
              <font-awesome-icon icon="heading" />
              <strong> - {{ event.action?.title?.fr }} </strong>
              <em> {{ event.action?.desc?.desc }} </em>
            </small>
            <small v-else class="info">
              <font-awesome-icon icon="calendar-day" />
              <em>Not set</em>
            </small>
          </template>

          <FormWidget title="Action:">
            <ActionSelector v-model="event.code" />
          </FormWidget>
          
          <FormWidget title="Module:">
            <ActionExec :action="event.code" @new_metadata="set_metadata" :old_metadata="event.data" :parent_event="event" />
          </FormWidget>
        </el-collapse-item>
        <el-collapse-item name="tasks">
          <template #title>
            <font-awesome-icon icon="list-check" />
            <h3>Tasks:</h3>

            <small v-if="event.task.tasked" class="info">
              <font-awesome-icon icon="heading" />
              <strong> - {{ event.task?.priority }} % </strong>

              <font-awesome-icon icon="heading" />
              <strong> - {{ event.task?.progress }} % </strong>

              <font-awesome-icon icon="heading" />
              <strong> - {{ event.task?.finish }} </strong>
            </small>
            <small v-else class="info">
              <font-awesome-icon icon="calendar-day" />
              <em>Not tasked</em>
            </small>
          </template>

          <FormWidget title="Tasked?">
            <el-checkbox v-model="event.task.tasked" label="Tasked?" name="type" />
          </FormWidget>
              
          <span v-if="event.task && event.task.tasked">
            <FormWidget title="Priority:">
              <el-rate v-model="event.task.priority" size="small" />
            </FormWidget>
              
            <FormWidget title="Progress:">
              <el-slider v-model="event.task.progress" />
            </FormWidget>

            <FormWidget title="Finish:">
              <el-date-picker v-model="event.task.finish" type="datetime" placeholder="Finish" />
            </FormWidget>
          </span>
        </el-collapse-item>
        <el-collapse-item name="rules" v-if="event.rule">
          <template #title>
            <font-awesome-icon icon="ruler" />
            <h3>Rules:</h3>
            <small v-if="event.rule" class="info">
              <font-awesome-icon icon="heading" />
              <span v-for="w,i in event.rule?.week" :key="i">
                <strong> {{ i }} :</strong>
                <em>{{ w.start }} -</em>
                <em>{{ w.end }}</em>
                <span>;</span>
              </span>
            </small>
            <small v-else class="info">
              <font-awesome-icon icon="calendar-day" />
              <em>Not tasked</em>
            </small>
          </template>
          
          <FormWidget title="Dates:">
            <el-date-picker v-model="event.rule.first" type="datetime" placeholder="First event" />  
            <el-date-picker v-model="event.rule.last" type="datetime" placeholder="Last event" />  
          </FormWidget>

          <FormWidget title="Frequency:" color="#FFA">
            <el-select v-model="rule.freq" placeholder="Category">
              <el-option label="Week" value="0" />
              <el-option label="Month" value="1" />
              <el-option label="Year" value="2" />
            </el-select>
          </FormWidget>
          
          <FormWidget title="Week:">
            <WeekTemplate :week="event.rule.week" />
          </FormWidget>

        </el-collapse-item>

        <el-collapse-item name="except" v-if="event.rule?.except">
          <template #title>
            <font-awesome-icon icon="stopwatch-20" />
            <h3>Except:</h3>

            <small class="info">
              <font-awesome-icon icon="stopwatch-20" />
              <strong> Count: {{ event.rule.except.length }}</strong>
            </small>
          </template>
          
          {{ event.rule.except }}
        </el-collapse-item>

        <el-collapse-item name="place">
          <template #title>
            <font-awesome-icon icon="location-dot" />
            <h3>Place:</h3>
            <small v-if="event.place" class="info">
              <font-awesome-icon icon="map-location" />
              <strong> - {{ event.place.alias }} </strong>
            </small>
          </template>

          <FormWidget v-if="event.place" title="Selected:">
            <PlaceLine :place="event.place" />
          </FormWidget>
          
          <FormWidget title="Change:">
            <PlaceSelector v-model="event.place" @selected="select_place"/>
            Alias {{ event.p }}
            Selected {{ place }}
          </FormWidget>
        </el-collapse-item>
        <el-collapse-item name="misc">
          <template #title>
            <font-awesome-icon icon="asterisk" />
            <h3> Misc:</h3>
          </template>

          {{ event }}
        </el-collapse-item>
        <el-collapse-item name="database">
          <template #title>
            <font-awesome-icon icon="database" />
            <h3> Database:</h3>

            <div>
              <small class="info">
                <font-awesome-icon icon="database" />
                <strong>{{ event.database }}</strong>
              </small>
            </div>
          </template>

          

          List:
          {{ dbs }}

          <DataBaseSelector :dbs="dbs" :old_database="event.database" @new_database="new_db" choose/>
        </el-collapse-item>
      </el-collapse>
    </div>


<!--
      Error pack:
      {{ error_pack }}
-->

      <el-affix position="bottom" :offset="20">
        <Popup :error_pack="this.error_pack" >
          <div style="width: 100%; display: inline-flex;" v-loading="loading">
              <ClickButton v-if="!event._id" name="Create" icon="plus" color="#0B0" @click="new_event"/>
              <ClickButton v-if="event._id" name="Edit" icon="rotate" color="#AA0" @click="edit_event"/>
              <ClickButton v-if="event._id" name="Delete" icon="minus" color="F00" @click="delete_event"/>
          </div>
        </Popup>
      </el-affix>

    </el-dialog>
  </div>
</template>

<script>
import ActionSelector from '../components/ActionSelector.vue'
import PlaceSelector from '@/components/PlaceSelector.vue'
import ActionExec from './ActionExec.vue'
import DataLine from './DataLine.vue'
import PlaceLine from './PlaceLine.vue'
import TimeSelector from '@/components/TimeSelector.vue'

import FormWidget from '@/widgets/FormWidget.vue'
import Popup from './Popup.vue'

import WeekTemplate from './WeekTemplate.vue'
import ClickButton from './ClickButton.vue'

import DataBaseSelector from './DataBaseSelector.vue'

import axios from 'axios'

//var _events = require('@/lib/events')

export default {
    name: 'EditEvent',
    props: ['event_i', 'show_i', 'dbs'],
    emits: ['update:event_i', 'done'],
    components: {
      ActionSelector, PlaceSelector,
      ActionExec,
      DataLine,
      PlaceLine,
      TimeSelector,
      FormWidget, Popup, WeekTemplate, ClickButton,
      DataBaseSelector

    },
    data() {
      return {
        api: process.env.VUE_APP_API,
        event:
        {
          id: '',
          title: '',
          desc: '',
          start: '',
          end: '',
          action:
          {
            code: '',
            title: {},
            desc: {},
            count: -1
          },
          task:
          {
            tasked: false,
            progress: 0,
            priority: 0,
            finish: null
          },
        },
        place: null,
        periods: { start: "2023-07-04T14:00:00.000Z", end: "2023-07-06T18:00:00.000Z" },
        rule:
        {
          freq: 'week',
          week: { mon: { start: '09:30', end: '18:00'}, tue: { start: '09:30', end: '18:00'}, thu: { start: '09:30', end: '18:00'}, sat: { start: '09:30', end: '13:00'}},
          first: '01-01-2023T00.00.00.000Z',
          last: '31-12-2023T00.00.00.000Z'
        },
        error_pack: {},
        show: false,
        loading: false
      }
    },
    methods:
    {
      /*
      displayed_date: function (date) {
        var dt = new Date(date);
        var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
        var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
        var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
        return str;
      },
      displayed_time: function (time) {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
      },
      */
      new_event: function()
      {
        this.loading = true;
        axios.post(this.api + 'events/', this.event, { withCredentials: true })
        .then(response =>
        {
          this.error_pack = response.data.error_pack || {};
          if (Object.keys(response.data.error_pack).length) { this.finish(); }
        })
        .catch(err =>
        {
          console.log("Err946:", err)
          this.error_pack =  err.response.data.error_pack || {};
        })
        .finally(() => this.loading = false)
      },
      edit_event: function()
      {
        this.loading = true;
        axios.put(this.api + 'events/' + this.event._id, this.event, { withCredentials: true })
        .then(response =>
        {
          this.error_pack = response.data.error_pack || {};
          if (!Object.keys(this.error_pack).length) { this.finish(); }
        })
        .catch(err =>
        {
          console.log("Err907:", err)
          this.error_pack =  err.response.data.error_pack || {};
        })
        .finally(() => this.loading = false)
      },
      delete_event: function()
      {
        this.loading = true;
        axios.delete(this.api + 'events/' + this.event._id, { withCredentials: true })
        .then(response =>
        {
          this.error_pack = response.data.error_pack || {};
          if (Object.keys(response.data.error_pack).length) { this.finish(); }
        })
        .catch(err =>
        {
          console.log("Err907:", err)
          this.error_pack =  err.response.data.error_pack || {};
        })
        .finally(() => this.loading = false)
      },
      finish()
      {
        this.show = false;
        this.$emit('done');
      },
      set_action(action)
      {
        this.action = action;
        console.log("Action log:", action)
      },
      set_metadata(meta)
      {
        this.event.data = meta;
      },
      new_db: function(db)
      {
        //console.log("abc:", db)
        this.event.database = db
      },
      cut: function()
      {

      },
      copy: function()
      {

      },
      paste: function()
      {

      },
      save: function()
      {
        this.$emit('update:event_i', this.event)
        this.show = false;
      },
      cancel: function()
      {
        this.show = false;
      },
      validate: function()
      {
        axios.post(process.env.VUE_APP_API + 'data/test/event/', this.event, { withCredentials: true })
        .then(response =>
        {
          console.log("VALIDATION: ", response.data);
        })
        .catch(err =>
        {
          console.log("Error of POST", err)
        });
      },
      select_place: function(place)
      {
        this.event.place = place;
      },
      db_colorize: function(database)
      {
        for(var i=0; i<this.dbs.length; i++)
        {
          if(this.dbs[i].id == database) { return this.dbs[i].color }
        }
        return '#AAA';
      }
    },
    computed:
    {
      displayed_date: function()
      {
        var st = new Date(this.event.start);
        var nd = new Date(this.event.end);

        if(st.getUTCFullYear() === nd.getUTCFullYear())
        {
          if(st.getUTCMonth() === nd.getUTCMonth())
          {
            if(st.getUTCDate() === nd.getUTCDate())
            {
              return 'Day:' + st.getUTCDate() + '/' + (st.getUTCMonth()+1) + '/' + st.getUTCFullYear()
            }
            else
            {
              return st.getUTCDate() + ' -> ' + nd.getUTCDate() + '/' + (nd.getUTCMonth()+1) + '/' + nd.getUTCFullYear();
            }
          }
          else
          {
            return st.getUTCDate() + '/' + (st.getUTCMonth()+1) + ' -> ' + nd.getUTCDate() + '/' + (nd.getUTCMonth()+1) + '/' + nd.getUTCFullYear();
          }
        }
        else
        {
          return st.getUTCDate() + '/' + (st.getUTCMonth()+1) + '/' + st.getUTCFullYear() + ' -> ' + nd.getUTCDate() + '/' + (nd.getUTCMonth()+1) + '/' + nd.getUTCFullYear();
        }      
      },
      displayed_time: function()
      {
        var st = new Date(this.event.start);
        var nd = new Date(this.event.end);

        var str = (st.getUTCHours() > 9 ? '' : '0') + st.getUTCHours() + ':' + (st.getUTCMinutes() > 9 ? '' : '0') + st.getUTCMinutes();

        if(st.getUTCHours() != nd.getUTCHours() || st.getUTCMinutes() != nd.getUTCMinutes())
        {
          str += ' > ' + (nd.getUTCHours() > 9 ? '' : '0') + nd.getUTCHours() + ':' + (nd.getUTCMinutes() > 9 ? '' : '0') +  nd.getUTCMinutes();
        }
        return str;
      }
    },
    watch:
    {
      event_i: function(new_event)
      {
        //console.log("ABC...New event:", new_event)

        if(new_event)
        {
          this.$emit('update:event_i', new_event)
          this.event = new_event;
          this.show = true;

          this.periods = { start: this.event.start, end: this.event.end };
          //console.log("Period set: ", this.periods)
        }
        else
        {
          this.show = false;
        }
      },
      show_i: function(s)
      {
        this.show = s;
      },
      periods: function(p)
      {
        this.event.start = p.start;
        this.event.end = p.end;
      }
    },
    mounted()
    {

      // PAtch event ## temporaire
      if(!this.event.task) { this.event.task = { tasked: false, progress: 0, priority: 0, finish: null }}

      if(!this.event.place) { this.event.place = { id: null, alias: null}}
    }
}
</script>

<style scoped>

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.info
{
  overflow-x: hidden;
  border: 1px solid #A00;
  border-radius: 3px;
  background-color: #EEE;
  color: #888;
  line-height: 1.5em;
}

.bar
{
  display: inline-flex;
  justify-content: stretch;
  overflow: hidden;
  background-color: black;
  color: blanchedalmond;
}
</style>