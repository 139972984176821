<template>
  <div>
    <Navigate v-on:update:range="Refresh" v-on:update:cursor="Locate" />
    <!--<DataBaseSelector v-model:dbs="dbs" v-model:actives="actives_dbs" />-->
    <DataBaseSelector v-model:dbs="dbs" @update:dbs="Refresh()"/>


    <div v-loading="loading">
      <!--<el-row v-for="c,i in sorted_events" :key="i" :class="isSame(c.date, now) ? 'today' : 'other'" :style="'background-color: ' + c.color + ';'">-->
      <el-row v-for="c,i in sorted_events" :key="i" class="other">
        <DailyInfo v-if="isSame(c.date, now)" :day="c.date" />
        <el-col :span="4">
          <div style="display: flexbox;">
            <DateBlock :date="c.date" @click="line_selected = i;" :color="c.color" :holiday="c.holiday"/>

            <div style="padding: 3px;">
              <el-button type="primary" plain size="small" @click="new_event(c.date)">
                <font-awesome-icon icon="calendar-plus" />
              </el-button>
            </div>
          </div>
        </el-col>

        <el-col :span="20">
          <div>
            <DayBar :day="c" />
          </div>
          <div v-for="evt,i in c.events" :key="i">
            <!--<DataLine :event="evt" :selected="false" @click="evt_edited = evt"/>-->
            <DataLine :event="evt" :selected="false" @click="evt_edited = evt;" :color="db_colorize(evt.database)"/>
          </div>
        </el-col>

      </el-row>      
    </div>


    <EditEvent :event_i="evt_edited" @done="Refresh" :dbs="dbs" choose />
    <EditPlace :place_i="plc_edited" @done="Refresh" />
  </div>
</template>


<script>
  var _events = require('@/lib/events');

  import DayBar from '@/components/DayBar.vue';

  import DataLine from '../components/DataLine.vue';
  import Navigate from '../components/NavigateTime.vue';
  //import TimeBar from '../components/TimeBar.vue'
  import EditEvent from '../components/EditEvent.vue';
  import EditPlace from '@/components/EditPlace.vue';

  import DateBlock from '@/components/DateBlock.vue';

  import DailyInfo from '@/components/DailyInfo.vue';

  import DataBaseSelector from '@/components/DataBaseSelector.vue';



  const
    data = {
      page: 0,
      now: new Date(),
      
      /*
      dbs: [
      { name: 'default', id:'default', color: '#F00', read: true, write: true, actived: true },
      { name: '61635458d305280024c545f3', id:'61635458d305280024c545f3', color: '#0FF', read: true, write: true, actived: false },
      { name: '62e52ff6e562d809ec94415b', id:'62e52ff6e562d809ec94415b', color: '#FA0', read: true, write: true, actived: true }
      ],
      */

      dbs: global.databases,

      sorted_events: [],

      edit_byday: {},
      error_pack: {},
      cli_date: new Date(),
      a_match: [],

      range: { start: null, end: null },
      cursor: {},
      drawer: false,
      evt_edited: null,
      plc_edited: null,
      line_selected: -1,

      week_list: [ 'sun', 'mon', 'tue', 'wen', 'thu', 'fri', 'sat'],
      loading: false
    };

  export default {
    name: 'NextView',
    components: {
      DayBar,
      EditEvent, EditPlace,
      Navigate,
      DataLine,
      DateBlock,
      //TimeBar,

      DailyInfo,
      DataBaseSelector
    },
    data() {
      return data;
    },
    methods:
    {
      Refresh: function(range)
      {
        if(range) { this.range = range }

        //console.log('RANGE:', this.range)
        //console.log("ACTIVES_DBS:" , this.actives_dbs)

        this.loading = true;
        _events.load_multiDB(this.range, this.actives_dbs)
        .then(evts => { this.sorted_events = evts; this.loading = false; })
        .catch(e => console.log("Err53:" + e))

        this.evt_edited = null;
      },
      Locate: function(cursor)
      {
        this.cursor = cursor;
      },
      isSame: function(date1, date2) 
      {
        return _events.is_same_day(date1, date2);
      },
      LaunchModal: function(event)
      {
        this.evt_edited = event;
        this.modal = true;
      },
      new_event: function(date)
      {
        //console.log("DATE:" + date)
        //var dt = new Date()

        this.evt_edited = {
          _id: null,
          title: "",
          desc: "",
          start: date,
          end: date,
          alarm: "1975-01-01T00:00:00.000Z",
          task: { tasked: false, priority: 0, progress: 0, finish: null },
          data: {},
          modified: null,
          created: null,
          mode: 0
        }
      },
      new_place: function(geo)
      {
        //console.log("DATE:" + date)
        //var dt = new Date()

        console.log("Geo > ", geo)
        this.plc_edited = {
          _id: null,
          name: "",
          alias: "",
          address: { street: '', city: '', country: '', lon: 0, lat: 0 },
          actions: [],
          mode: 0
        }
      },
      db_colorize: function(database)
      {
        if(this.dbs)
        {
          for(var i=0; i<this.dbs.length; i++)
          {
            if(this.dbs[i].id == database) { return this.dbs[i].color }
          }          
        }

        return '#AAA';
      }
    },
    /*
    watch:
    {
      dbs: function()
      {
        this.Refresh();
      }
    },
    */
    computed:
    {
      actives_dbs: function()
      {
        if(this.dbs)
        {
          var actives = [];

          for(var i=0; i<this.dbs.length; i++)
          {
            var db = this.dbs[i];
            if(db.actived) { actives.push(db.id) }
          }

          return actives;
        }
        else
        {
          return ['default'];
        }

      }
    },
    mounted()
    {
      this.Refresh();
    }
  }
</script>

<style scoped lang="css">

.other
{
  background-color: #FFF;
  border: 2px solid #EEE;
}

</style>