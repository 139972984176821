<template>
  <div class="about">
    <h1>This is an SignIn page</h1>

    User: {{ user }}
    Ret User {{ ret_user }}

    <el-steps :active="step" align-center finish-status="success">
      <el-step title="Informations de base" />
      <el-step title="Validation" />
      <el-step title="Informations complementaires" />
    </el-steps>

    <el-button style="margin-top: 12px" @click="step++">Next step {{ step }}</el-button>


    <el-collapse v-model="step" accordion>
      <el-collapse-item title="Base" :name="1">
        <div>
          <el-form :model="user" label-width="100px" class="demo-ruleForm" status-icon v-loading="wait">
            <el-form-item label="Gender:" prop="gender" :error="error_pack.gender">
              <el-radio-group v-model="user.gender">
                <el-radio label="Men" />
                <el-radio label="Woman" />
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Email:" prop="email" :error="error_pack.email">
              <el-input v-model="user.email" />
            </el-form-item>

            <el-row>
              <el-col :xs="24" :sm="12">
                <el-form-item label="Password:" prop="password" :error="error_pack.pass">
                  <el-input v-model="user.pass" />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item label="Confirm:" prop="confirm" :error="error_confirm">
                  <el-input v-model="pass_confirm" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :xs="24" :sm="12">
                <el-form-item label="Lastname:" prop="lastname" :error="error_pack.lastname">
                  <el-input v-model="user.lastname" />
                </el-form-item>        
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item label="Firstname:" prop="firstname" :error="error_pack.firstname">
                  <el-input v-model="user.firstname" />
                </el-form-item>        
              </el-col>
            </el-row>



            <el-form-item label="Country:" prop="country" :error="error_pack.country">
              <el-select v-model="user.country" placeholder="Country">
                <el-option label="France" value="france" />
                <el-option label="Belgium" value="belgium" />
              </el-select>
            </el-form-item>

            <el-form-item label="Birthday:" :error="error_pack.birth">
              <el-form-item prop="birth">
                <el-date-picker
                  v-model="user.birth"
                  type="date"
                  label="Birthday"
                  placeholder="Birthday"
                  style="width: 100%"
                />
              </el-form-item>
            </el-form-item>

            <!--
            <el-form-item label="Instant delivery" prop="delivery">
              <el-switch v-model="user.delivery" />
            </el-form-item>
            <el-form-item label="Activity type" prop="type">
              <el-checkbox-group v-model="user.type">
                <el-checkbox label="Online activities" name="type" />
                <el-checkbox label="Promotion activities" name="type" />
                <el-checkbox label="Offline activities" name="type" />
                <el-checkbox label="Simple brand exposure" name="type" />
              </el-checkbox-group>
            </el-form-item>
            -->

            <el-form-item label="Biography" prop="bio">
              <el-input v-model="user.bio" type="textarea" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">Create</el-button>
              <el-button type="default">Reset</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="'Validation' + user_id" :name="2">
        <div>
          An user account 
          An email is sent to your email address:
          {{ ret_user }}
          <el-button @click="check_validation">Check Activation</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Complementary" :name="3">
        <div>
          Consistent within interface: all elements should be consistent, such
          as: design style, icons and texts, position of elements, etc.
        </div>
      </el-collapse-item>
    </el-collapse>



  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'SignInView',
    components: {
    },
    data() {
      return {
        user: 
        {
          lastname: '',
          firstname: '',

          birth: null,

          email: '',
          pass: '',
          phone: '',

          country: 'France',
          bio: ''

        },
        pass_confirm: '',
        error_pack: {},
        wait: false,
        step: 0,
        ret_user: {},
        user_id: ''
      }
    },
    methods:
    {
      submit: function()
      {
        this.wait = true;
        axios.post(process.env.VUE_APP_API + 'user/', { user: this.user }, { withCredentials: true })
        .then(response =>
        {
            var r = response.data;
            console.log("Result...", r);

            this.error_pack = r.error_pack;

            this.step = r.step;
            this.ret_user = r.user;
            console.log("STEP of SignIn: " + r.step);
        })
        .catch(function(err) {
            console.log("Error of post", err);
        })
        .finally(() => this.wait = false);
      },
      check_validation: function()
      {
        axios.get(process.env.VUE_APP_API + 'user/valid', { withCredentials: false })
        .then(response =>
        {
            console.log("Response of get", response);
            
            this.ret_user = response.data.user;
            //this.$emit("update:user", this.user);
        })
        .catch(function(err)
        {
            console.log("Error of post", err);
        });
      }
    },
    computed:
    {
      error_confirm: function()
      {
        if(this.user.pass != this.pass_confirm) { return 'Password not same'}
        else { return null; }
      }
    },
    mounted()
    {

    }
  }
</script>