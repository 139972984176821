<template>
  <div>
    <h5>Tasks</h5>

      <p>Count: {{tasks.length}}</p>

      <!--
    <DataLine v-for="t,i in tasks" :key="i" :event="t"/>

    <h4>Liste</h4>
    <div style="display: inline-flex;">
      <DataSquare v-for="t,i in tasks" :key="i" :event="t" />
    </div>
-->

    <el-row style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;">
      <el-col :span="24">
        <strong>Priority</strong>
      </el-col>
    </el-row>

    <el-row style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid;">
      <el-col v-for="priority in [0,1,2,3,4,5]" :key="priority" :span="4">
        <strong>{{ priority }}</strong>
      </el-col>
    </el-row>

    <!--<el-row v-for="finish in [{ from:-1, to:-7 }, { from:-7, to:-15 }, { from:-15, to:-30 }, { from:-30, to:-90 }] " :key="finish">-->
    <el-row v-for="finish,i in [{ from:-500, to:-30 }, { from:-30, to:-15 }, { from:-15, to:-7 }, { from:-7, to:-1 }, { from:1, to:7 }, { from:7, to:15 }, { from:15, to:30 }, { from:30, to:500 }] " :key="i" :style="'background-color: ' + (i%2 ? '#FFF;' : '#FFE;')" class="line">
      <el-col :span="24">
        <div class="title">
          <span>Between {{ finish.from }} days to {{ finish.to }} days {{ finish.from < 0 ? ' in the past ' : ' in the future' }}</span>
        </div>
      </el-col>      
      <el-col v-for="priority in [0,1,2,3,4,5]" :key="priority" :span="4">
        <div v-for="t,i in tasks" :key="i">
          <DataSquare v-if="is_good_case(t, priority, finish.from, finish.to)" :event="t" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  var _events = require('@/lib/events');

  //import DataLine from '../components/DataLine.vue'
  //import Viewing from '../components/Viewing.vue'
  //import Navigate from '../components/Navigate.vue'

  //import DataLine from '../components/DataLine.vue'
  import DataSquare from '@/components/DataSquare.vue'


  const
    data = {
      tasks: []
      };

  export default {
    name: 'TasksView',
    components:
    {
      DataSquare
    },
    props:
    {

    },
    data() {
      return data;
    },
    methods: {
      displayed_date: function (date) {
        var dt = new Date(date);
        var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
        var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
        var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
        return str;
      },
      displayed_time: function (time) {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
      },
      is_good_case: function(task, priority, min_days, max_days)
      {
        if(!task.task) { return false; }
        var tsk_priority = task.task.priority;
        if(priority != tsk_priority) { return false; }
        
        var tsk_end = new Date(task.end)
        var now = new Date();
        var min = new Date();
        min.setDate(tsk_end.getUTCDate() + min_days);
        var max = new Date();
        max.setDate(tsk_end.getUTCDate() + max_days);

        if(now < min || now > max) { return false; }
        
        //var tsk_finish = new Date(task.task.finish);        

        return true;
      },
      get_undo_tasks: function()
      {
        var start = new Date("2022-01-01T00:00:00.000Z");
        var end = new Date("2025-10-26T10:20:15.146Z");

        _events.undo({ start: start, end: end }).then(tasks => { this.tasks = tasks; } ).catch(e => console.log("abcdef:"+e))

        /*
        axios.get(process.env.VUE_APP_API + 'tasks/', {
          params: { start: start, end: end, undo: true },
          withCredentials: true
        }).then(response => {
          this.tasks = response.data.data;
          this.populate_actions_data;
        }).catch(console.log("errrr"));
        */



      }

    },
    watch: {

    },
    mounted: function ()
    {
      console.log("* TasksView")
      setInterval(function () {
        this.cli_date = new Date();
      }, 5000);
      this.get_undo_tasks();
    }
  }
</script>

<style scoped lang="css">
  .alias
  {
      font:bolder;
      color: #33E;
      font-style: oblique;
  }

  .stars
  {
    margin: 0%;
    padding: 0%;
    border: 0%;
  }

  .line
  {
    border-radius: 10px;
    border: 1px solid;
  }

  .title
  {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: smaller;
    font-weight: bold;
    font-style: italic;

    text-align: left;

    width: 100%;
  }
</style>