<template>
  <div>
    <div>
      <Navigate v-on:update:range="Refresh" v-on:update:cursor="Locate"/>
    </div>

    <el-row>
      <el-col :span="3">
      </el-col>

      <el-col v-for="day in ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di']" :key="day" :span="3">
        <div>
          {{ day }}
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24"></el-col>
    </el-row>
    <el-row v-for="s in 5" :key="s">
      <el-col :span="3">
        <span class="week">Week: <strong>{{ weeknum(s) }}</strong></span>
      </el-col>
      <el-col v-for="d in 7" :key="d" :span="3">
        <!--Case: {{ 7*(s-1) + (d-1) }} -->
        <DataBox :active="actived(7*(s-1) + (d-1))" :day="sorted_events[7*(s-1) + (d-1)]" @selected="selected" />
      </el-col>
    </el-row>

    <EditEvent :event_i="selected_event" />

  </div>
  
</template>

<script>
  var _events = require('@/lib/events');
  
  //import Connect from '../components/Connect.vue'
  import Navigate from '../components/NavigateTime.vue'

  import DataBox from '../components/DataBox.vue'
  import EditEvent from '../components/EditEvent.vue'

  //import TimeBar from '../components/TimeBar.vue'

  const
    data = {
      calendar: [],
      sorted_events: [],

      edit_byday: {},
      selected_event: null,
      error_pack: {},
      a_match: [],

      range: {
        start: null,
        end: null
      },
      cursor: {},
      now: new Date()
    };

  export default {
    name: 'MonthView',
    components: {
      //Connect,
      Navigate,
      DataBox,
      EditEvent,
      //TimeBar
    },
    props: {
      url: String,
      link: String
    },
    data() {
      return data;
    },
    methods: {

      actived: function(index)
      {
        //console.log("************: " + index, this.sorted_events[index])
        if(this.sorted_events[index])
        {
          var dt = new Date(this.sorted_events[index].date);
          return dt.getUTCMonth() == this.cursor.month;
        }
      },
      weeknum: function(row)
      {
        var tot_days = []
        
        if(this.cursor.year % 4) { tot_days = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366 ] }
        else { tot_days = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365 ] }

        var first = this.sorted_events[7*row];
        if(first)
        {
          var dt = new Date(first.date)

          var days = 0;
          days += dt.getDate();
          days += tot_days[dt.getMonth()];

          return Math.round(days/7);
        }
        else
        {
          return '???'
        }



      },
      selected: function(event)
      {
        //console.log("ABCDEF", event)
        this.selected_event = event;

      },
      finish() {
        this.Refresh();
      },
      Refresh(range)
      {
        _events.load(range)
        .then(evts => this.sorted_events = evts )
      },
      Locate(cursor) {
        this.cursor = cursor;
        console.log("Locate cursor:", cursor)
      }
    },
    watch: {
      calendar: function () {
        this.sorted_events = [];

        var s = Math.floor(new Date(this.range.start).getTime() / (24 * 60 * 60 * 1000));
        var e = Math.floor(new Date(this.range.end).getTime() / (24 * 60 * 60 * 1000));

        for (var i = s; i <= e; i++) {
          var offset = new Date();
          offset.setTime(24 * 60 * 60 * 1000 * i)

          var events = [];
          for (var c = 0; c < this.calendar.length; c++) {
            var st = new Date(this.calendar[c].start);

            if ((offset.getUTCDate() == st.getUTCDate()) && (offset.getUTCMonth() == st.getUTCMonth())) {
              events.push(this.calendar[c])
            }
          }

          this.sorted_events.push({
            date: offset,
            events: events,
            zones: this.zones_calc(events)
          });
        }

      }
    },
    mounted: function ()
    {

    }
  }
</script>

<style scoped lang="css">
.table
{
  margin: 1px;
  padding: 1px;
}

.week
{
  text-orientation: sideways;
  writing-mode: vertical-lr;
  font-size: larger;
}
</style>