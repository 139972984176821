<template>
    <div class="line" :style="css">
        <el-row v-if="place">
            <el-col v-if="place.type || place_i.type" :span="place.type || place_i.type ? 2 : 0" style="text-align: center;">
                <el-image v-if="place.type || place_i.type" style="width: 32px; height: 32px;" :src="api + 'places/icon/?type=' + place.type || place_i.type" fit="cover" />
            </el-col>
            <el-col :span="place.type || place_i.type ? 22 : 24">
                <span>
                    <el-button @click="get_info">Test</el-button>
                    <strong>@{{place.alias || place_i.alias}} - {{ place.name || place_i.name }}</strong>

                    <small style="color: #AAA; float: right;" class="hidden-sm-and-down">DB:{{ place.database || place_i.database }}</small>
                </span>
                <span v-if="place.geo">
                    <br/>
                    Lon:
                    <span class="lcd" style="color: green;"> {{ place.geo.coordinates[0] }} </span>
                    Lat:
                    <span class="lcd" style="color: blue;"> {{ place.geo.coordinates[1] }} </span>
                </span>
                <span v-else-if="place_i.geo">
                    <br/>
                    Lon:
                    <span class="lcd" style="color: green;"> {{ place_i.geo.coordinates[0] }} </span>
                    Lat:
                    <span class="lcd" style="color: blue;"> {{ place_i.geo.coordinates[1] }} </span>
                </span> 
            </el-col>
        </el-row>
        <el-row v-else>
            <em>Place not defined</em>
        </el-row>
    </div>
</template>

<script>
//import RatingStarsSelector from '@/components/RatingStarsSelector.vue'
import axios from 'axios';


const data =
{
    api: process.env.VUE_APP_API,
    colors:
    [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
    ],
    place_i : { type: null }
};

export default {
  name: 'PlaceLine',
  props: ['place', 'selected'],
  emits: ['update:place', 'done'],
  components:
  {
    //RatingStarsSelector
  },
  data() { return data },
  methods:
  {
    displayed_date: function (date)
    {
        var dt = new Date(date);
        var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
        var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
        var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
        return str;
    },
    displayed_time: function (time)
    {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
    },
    get_info: function()
    {
        if(this.place?._id)
        {
            axios.get(process.env.VUE_APP_API + 'place/search', { params: { id: this.place._id }, withCredentials: true })
            .then(response => {
                //console.log("RESPONSE.DATA******", response.data)
                this.place_i = response.data[0]; //
            })
            .catch(e => console.log("ERR25: " + e));            
        }

    }
  },
  computed:
  {
    css: function()
    {
        if(this.place && this.place.type)
        {
            return "background-image: url('" + this.api + 'places/icon/?type=' + this.place.type + "');";
        }
        else
        {
            return "background-color: #FFA"
        }
    }
  },
  watch:
  {
    place: function(p)
    {
        console.log("WATCHING PLACE CHANGE:", p)
        this.get_info();
    }

  },
  updated()
  {
    this.place_i = {}
  }
}

</script>

<style scoped>
.line
{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    width: 100%;
    max-height: 300px;
    margin: 0px;
    padding: 0px;

    border: 1px outset gainsboro;
    border-radius: 5px;
    
    background-position:top;
    background-color:rgba(255,255,255,0.2);
    background-size: 24px;
    background-repeat: no-repeat;
}

@font-face
{
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

.lcd
{
    text-align: center;

    font-size: 12px;
    font-weight: bold;
    font-family: LCD;

}




</style>