<template>
    <div>
      <svg viewBox="0 0 80 4" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <!-- <rect width="100" height="10" fill="#FFF" />-->
        <rect width="100" height="10" fill="#FFF"/>
  
        <g v-for="evt,i in elements" :key="i">
            <rect :x="evt.coords.x" :y="evt.coords.y" :width="evt.coords.w" :height="evt.coords.h" :fill="evt.coords.color" />

            <image v-if="evt.code" :xlink:href="api + 'action/icon/' + evt.code" :x="evt.coords.x + evt.coords.w /2" :y="1" height="2" width="5" />
  
            <text :x="evt.coords.x" :y="evt.coords.y + 1" font-size="1.5" font-weight="bold" text-anchor="left" fill="#FFF">{{ time(evt.start) }}</text>
            <text :x="evt.coords.x + evt.coords.w - 3" :y="evt.coords.y + 1" font-size="1.5" font-weight="bold" text-anchor="right" fill="#FFF">{{ time(evt.end) }}</text>

            <!-- <text :x="evt.coords.x" :y="evt.coords.y" font-size="1.7" font-weight="bold" text-anchor="left" fill="#FFF">{{ evt.title }}</text> -->
        </g>
      </svg>
    
          <!--<text :x="i*100/30" y="12" font-size="2" text-anchor="middle" fill="#FFF">{{a.events.length}}</text> -->

    </div>
  </template>
  
  <script>
  
  export default ({
    name: 'DayBar',
    props: { day : Object },
    data()
    {
      return {
        api: process.env.VUE_APP_API,
        elements: []
      }
    },
    methods:
    {
      day_of_month: function(date)
      {
        var dt = new Date(date)
        return dt.getDate();
      },
      day_of_week: function(date)
      {
        var dt = new Date(date);
        return dt.getUTCDay();
      },
      time: function(dt)
      {
        var date = new Date(dt)
        var hr = ((date.getUTCHours() > 9) ? '' : '0') + date.getUTCHours();
        var min = ((date.getUTCMinutes() > 9) ? '' : '0') + date.getUTCMinutes();
        return hr + ':' + min;
      },
      calc: function()
      {
        this.elements = [];
        var evts = this.day.events;

        var today = new Date(this.day.date);
        today.setUTCHours(8,30,0,0);
        var first_mins = today.getTime() / 1000 / 60;

        today.setUTCHours(23,0,0,0);
        var last_mins = today.getTime() / 1000 / 60;

        var total_mins = last_mins - first_mins

        for(var i=0; i<evts.length; i++)
        {
            var evt = evts[i];

            var st = new Date(evt.start);
            var nd = new Date(evt.end);

            var start_mins = st.getTime() / 1000 / 60;
            var end_mins = nd.getTime() / 1000 / 60;

            // 100 = largeur temporaire !

            var colors = [ '#000', '#080', '#088', '#008', "#808", "#0F0", "#000", "#FFF" ]

            var larg = 80;
            evt.coords =
            {
                x: larg * (start_mins - first_mins) / total_mins,
                y: 3,
                w: larg * (end_mins - start_mins) / total_mins,
                h: 1,
                color: colors[i],
                start: st.getUTCHours() + ':' + st.getUTCMinutes(),
                end: nd.getUTCHours() + ':' + nd.getUTCMinutes()
            };

            this.elements.push(evt)
            //console.log("Start: " + (start_mins - first_mins) + " - End: " + (end_mins - first_mins))
        }
      }
      
    },
    watch:
    {
        day: function()
        {
            this.calc();
        }
    },
    mounted()
    {
        this.calc();
    }
  
  })
  </script>
  
  <style>
  
  
  </style>