<template>
    <!-- <div class="line" :style="css"> -->
    <div class="line">
        
        <div id="background">
            <!-- Ici on met se qui est Background -->
            <!--
            <span class="lcd">{{displayed_time(event.start)}} - {{displayed_time(event.start)}}</span>
            -->
        </div>

        <el-row>
            <el-col :span="event.code ? 2 : 0" class="icone">
                <el-image v-if="event.code" style="width: 32px; height: 32px;" :src="api + 'action/icon/' + event.code" fit="cover" />
            </el-col>
            <el-col :span="event.code ? 22 : 24">
                <span>
                    <span class="lcd" style="color: red;"> {{ displayed_time(event.start) }}</span>
                    
                    <strong v-if="event.title"> - {{ event.title }}</strong>
                    <strong v-else-if="event.action && event.action.title" style="color: #070"> - <em>{{ event.action.title.fr }}</em></strong>
                    <strong v-else style="color: #DDD"><em>No title</em></strong>
                    <el-image v-if="event.code" style="width: 16px; height: 16px;" :src="api + 'action/icon/' + event.code" fit="cover" />

                    <small :style="'background-image: linear-gradient(45deg, #FFF 0%, '+color+' 100%); float: right;'" class="hidden-sm-and-down">
                        
                        <span :style="'color:' + color + ';'">DB:</span>
                        {{ event.database }}
                    </small>
                </span>
                <span style="overflow-y: hidden;">
                    <br/>
                    <em> {{ event.desc }}</em>
                    <span v-if="event.place && event.place.alias">
                        <small style="color: blue;"><em>@{{ event.place.alias }}</em></small>
                    </span>
                    <span v-if="event.data && event.data.abstract">
                        <small style="color: orange;"><em>*{{ event.data.abstract }}</em></small>
                    </span>                    
                </span>

                        
                <span v-if="event.delta" style="float: right;" >
                    <span v-if="event.delta.start < 0" style="color: #00F;" >
                        <font-awesome-icon icon="fa-solid fa-hourglass-start"/>
                        <strong> - {{ Math.abs(event.delta.start) }}</strong>
                    </span>
                    <span v-if="event.delta.start > 0 && event.delta.end < 0" style="color: #0C0;" >
                        <font-awesome-icon icon="fa-solid fa-hourglass-half"/>
                        <strong> left {{ Math.abs(event.delta.end) }}</strong>
                    </span>
                    <span v-if="event.delta.end > 0"  style="color: #C00;" >
                        <font-awesome-icon icon="fa-solid fa-hourglass-end"/>
                        <strong> + {{ Math.abs(event.delta.end) }}</strong>
                    </span>
                </span>  

                <div v-if="event.task && event.task.tasked">
                    <span v-if="event.task.progress == 100" style="float: right; font-style: italic;">
                        <el-icon color="#0F0"><Select /></el-icon> Done! <span v-if="event.task.finish"> at {{ displayed_date(event.task.finish) }} </span>
                    </span>
                    <span v-else>
                        <!--
                        <div class="hidden-xs-only" style="display:flex">
                            <RatingStarsSelector :stars="event.task.priority" :editable="false"/>
                            <el-progress :percentage="parseInt(event.task.progress)" :color="colors" style="width:85%; margin: 0%; border: 0%; padding: 0px;" />
                        </div>-->
                        <div style="display:flex; overflow: hidden;">   
                            <RatingStarsSelector :stars="event.task.priority" :editable="false" style=" margin: 0%; border: 0%; padding: 0px;"/>
                            <el-progress :percentage="parseInt(event.task.progress)" :color="colors" style="width: 85%; margin: 0%; border: 0%; padding: 0px;" />
                        </div>
                        <!--
                        <div class="hidden-sm-and-up">
                            <strong>{{ event.task.priority }}</strong>
                            <el-icon color="#FA8"><Star /></el-icon>
                        </div>
                    -->
                    </span>
                </div>

                <div v-if="event.rule" style="float: right;">
                    <small>
                        <font-awesome-icon icon="forward-fast" />
                        {{ displayed_date(event.rule.first) }}
                        -
                        <font-awesome-icon icon="forward-fast" />
                        {{ displayed_date(event.rule.last) }}
                    </small>
                </div>

            </el-col>
        </el-row>

    </div>
</template>

<script>
import RatingStarsSelector from '@/components/RatingStarsSelector.vue'

export default {
  name: 'DataLine',
  props: ['event', 'selected', 'color'],

    emits: ['update:event', 'done'],
  components:
  {
    RatingStarsSelector,
  },
  data() {
    return {
        api: process.env.VUE_APP_API,
        colors:
        [
            { color: '#F00', percentage: 20 },
            { color: '#FA0', percentage: 40 },
            { color: '#AF0', percentage: 60 },
            { color: '#5F0', percentage: 80 },
            { color: '#0F0', percentage: 100 }
        ],
        event2: {}
    }

    },
  methods:
  {
    displayed_date: function (date)
    {
        var dt = new Date(date);
        var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
        var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
        var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()] + ' ' + dt.getUTCFullYear();
        return str;
    },
    displayed_time: function (time)
    {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
    },
    /*
    display: function(dt)
    {
        //var d = new Date(dt);

    }
    */
  },
  computed:
  {
    css: function()
    {
        //return "background-image: url('" + this.api + 'action/icon/' + this.event.code + "');";
        return "background-image: url('" + this.api + 'action/icon/' + this.event.code + "');";
    },
  },
  watch:
  {
    event: function()
    {
        this.$emit('update:event', this.event)
    }
  },
  mounted()
  {

    this.event2 = this.event;
  }

}

</script>

<style scoped>

#background
{
   position: absolute;

   font-size: 40px;
   bottom: 50%;
   right: 30px;
   /*
   left: 0;
   bottom: 0;
   right: 0;
   */
   z-index: 0;
   overflow: hidden;
}
.line
{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;

    width: 100%;

    min-height: 40px;
    max-height: 1000px;
    margin: 0px;
    padding: 0px;

    border: 1px outset gainsboro;
    border-radius: 5px;
    
    background-position: center;
    
    background-color:rgba(255,255,255,0.4);
    background-size: 16px;
    background-repeat: no-repeat;


    overflow-wrap: break-word;
    overflow-y: auto;

    position: relative;
}

@font-face
{
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

.lcd
{
    text-align: center;

    font-size: 12px;
    font-weight: bold;
    font-family: LCD;
}

.icone
{
    font-size:medium;
    opacity: 0.2;

    image-orientation: 20deg;
}

</style>