<template>
        <!-- Cursor Param: {{cursor}} <b-badge variant="danger">Cursor</b-badge> <br/> -->
        <!-- Cursor Date: {{cursor_dt}} <b-badge variant="success">Date</b-badge> -->    

        <!--
    <el-row>
        <el-col :span="6">
            <el-button type="info" plain @click="year(-1);">-- {{labels.past_year}}</el-button>
        </el-col>
        <el-col :span="6">
            <el-button type="danger" plain @click="month(-1);">-- {{labels.past_month}}</el-button>
        </el-col>
        <el-col :span="6">
            <el-button type="warning" plain @click="month(1);">-- {{labels.futur_month}}</el-button>
        </el-col>
        <el-col :span="6">
            <el-button type="info" plain @click="year(1);">-- {{labels.futur_year}}</el-button>
        </el-col>
    </el-row>
        -->

    <el-button-group style="width: 100%; align-items: center;">
        <el-button type="info" text @click="year(-1);">
            <font-awesome-icon icon="backward-fast" />
            {{labels.past_year}}
        </el-button>
        <el-button type="warning" text @click="month(-1);">
            <font-awesome-icon icon="backward" />
            {{labels.past_month}}
        </el-button>
        <el-button type="warning" text @click="month(1);">
            {{labels.futur_month}}
            <font-awesome-icon icon="forward" />
        </el-button>
        <el-button type="info" text @click="year(1);">
            {{labels.futur_year}}
            <font-awesome-icon icon="forward-fast" />
        </el-button>
        <el-button type="info" text @click="set_date(now.toISOString());">
            <font-awesome-icon icon="play" />
            {{displayed_date(now)}}
        </el-button>        
    </el-button-group>


</template>

<script>
import axios from 'axios';
export default {
    name: 'NavigateTime',
    props: { },
    data: function() {
        return {
            now: new Date(),
            error_pack: {},
            range: { start: new Date(), end: new Date()},
            cursor: { day: 0, week: 0, month: 0, monthday: 0, year: 0, yearday: 0, date: new Date() },
            labels : { past_year : 'PY', past_month: 'PM', past_week: 'PW', past_day: 'PD', futur_day: 'FD', futur_week: 'FW', futur_month: 'FM', futur_year: 'FY' }
        }
    },
    methods: {
        debug: function() {
            sessionStorage.setItem('myCat', 'Tom');
            console.log("LOGGED!");
        },
        calculate: function()
        {
            // Range 1 - 31
            //this.range.start = new Date(Date.UTC(this.cursor.year, this.cursor.month, 1, 0, 0, 0, 0));
            
            //this.range.end = new Date(Date.UTC(this.cursor.year, this.cursor.month + 1, 1, 0, 0, 0, 0));
            //this.range.end.setDate(0);
            
/*
            this.range.start = new Date(Date.UTC(this.cursor.year, this.cursor.month, 1, 0, 0, 0, 0));
            this.range.start.setUTCDate(-this.range.start.getUTCDay() + 1);

            this.range.end = new Date(this.range.start);
            this.range.end.setDate(this.range.start.getDate() + (6 * 7));
*/


            // Range First Monday and last Sunday
            this.range.start = new Date(Date.UTC(this.cursor.year, this.cursor.month, 1, 0, 0, 0, 0));
            this.range.start.setUTCDate(-this.range.start.getUTCDay() + 2);

            this.range.end = new Date(Date.UTC(this.cursor.year, this.cursor.month + 1, 1, 0, 0, 0, 0));
            this.range.end.setDate(8 - this.range.end.getUTCDay());


            this.labels.past_year = this.cursor.year - 1;
            this.labels.futur_year = this.cursor.year + 1;

            var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];

            this.labels.past_month = m[(this.cursor.month == 0) ? (11) : (this.cursor.month - 1)];
            this.labels.futur_month = m[(this.cursor.month == 11) ? (0) : (this.cursor.month + 1)];

            this.$emit('update:cursor', this.cursor);
            this.$emit('update:range', { start: this.range.start.toISOString(), end: this.range.end.toISOString() });
        },
        set_range: function()
        {
            axios.get(process.env.VUE_APP_API + 'api/range', { withCredentials: true }).then(response => {
                console.log("Response of get", response);
                this.user = response.data.user;
            }).catch(function(err) {
                console.log("Error of post", err);
            });
        },
        year: function(inc)
        {
          this.cursor.year += inc;
          if(this.cursor.year < 1980) { this.cursor.year = 1980; }
          if(this.cursor.year > 2050) { this.cursor.year = 2050; }

          this.cursor.date.setUTCFullYear(this.cursor.year);

          this.calculate();
        },
        month: function(inc)
        {
          this.cursor.month += inc;
          if(this.cursor.month < 0) { this.cursor.month = 11; this.cursor.year -= 1; }
          if(this.cursor.month > 11) { this.cursor.month = 0; this.cursor.year += 1; }

          this.cursor.date.setUTCMonth(this.cursor.month);

          this.calculate();
        },
        week: function(inc)
        {
          this.cursor.week += inc;
          if(this.cursor.week < 0) { this.cursor.week = 52; }
          if(this.cursor.week > 52) { this.cursor.week = 0; }
        },
        day: function(inc)
        {
          this.cursor.yearday += inc;
          if(this.cursor.yearday < 0) { this.cursor.yearday = 366; this.cursor.month = 11; this.year(-1); }
          if(this.cursor.yearday > 366) { this.cursor.yearday = 0; this.cursor.month = 0; this.year(1); }
        },
        set_date: function(dt)
        {
            const date = new Date(dt);
            const first = new Date(date.getUTCFullYear(), 0, 1, 0, 0, 0, 0);
            const first_monday = new Date(date.getUTCFullYear(), 0, first.getUTCDay(), 0, 0, 0, 0);

            this.cursor.year = date.getUTCFullYear();
            this.cursor.month = date.getUTCMonth();
            this.cursor.monthday = date.getUTCDate();
            this.cursor.yearday = Math.floor((date - first) / 1000 / 60 / 60 / 24);
            this.cursor.week = Math.floor(((date - first_monday) / 1000 / 60 / 60 / 24 / 7) + 1);

            this.calculate();
        },
        displayed_date: function (date)
        {
            var dt = new Date(date);
            var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
            var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
            var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()] + ' ' + dt.getFullYear();
            return str;
        }

    },
    computed:
    {
        cursor_dt: function()
        {
            const dt = new Date(this.cursor.year, this.cursor.month, this.cursor.monthday, 0, 0, 0, 0)
            return dt;
        }
    },
    mounted()
    {
        setInterval(() => { this.now = new Date(); }, 60000);
            
        this.range.start = new Date(this.now.getFullYear(), this.now.getMonth(), 1, 0, 0, 0, 0);
        this.range.end = new Date(this.now.getFullYear(), this.now.getMonth(), 30, 0, 0, 0, 0);
        this.set_date(this.now);
    }
}
</script>

