<template>
    <div>
      <h1>Projects</h1>

      <DateBlock :date="now" />
        
    </div>
</template>
  
  
<script>
    //import DataLine from '../components/DataLine.vue'
    //import Navigate from '../components/NavigateTime.vue'
    //import TimeBar from '../components/TimeBar.vue'
    //import EditEvent from '../components/EditEvent.vue';
  
    import DateBlock from '@/components/DateBlock.vue';
  
    import axios from 'axios';
  
    const
      data = {
        now: new Date()
      };
  
    export default {
      name: 'NextView',
      components: {
        //EditEvent,
        //DataLine,
        DateBlock,
        //TimeBar,
          
      },
      data() {
        return data;
      },
      methods: {
        displayed_date: function (date) {
          var dt = new Date(date);
          var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
          var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
          var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
          return str;
        },
        displayed_time: function (time) {
          var tm = new Date(time)
          var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
          var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
          return hr + ':' + min;
        },

        Refresh: function()
        {
          var para = { start: "2000-01-01T10:20:15.146Z", end: "2025-10-26T10:20:15.146Z" };

          axios.get(process.env.VUE_APP_API + 'tasks/list', { params: para, withCredentials: true })
          .then(response => {
            this.tasks = response.data.data;
            this.database = response.data.database;
            //console.log("Return:/", response.data)
            this.Classification();
          })
          .catch((e) => console.log("Axios.get err:" , e));
        },
        DaysTo: function(d1, d2)
        {
            var days = parseInt((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
            
            return days;
        }
      },
      mounted()
      {
  
  
      }
    }
  </script>
  
  <style scoped lang="css">
    @font-face {
      font-family: 'LCD';
      src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
      /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
    }
  
  </style>