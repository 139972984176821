<template>
  <div class="about">
    <h1>This is an loginpage</h1>
    <span>Bienvenue sur Organize!</span>

    <ConnectBlock />
  </div>
</template>

<script>
  import ConnectBlock from '@/components/ConnectBlock.vue';
  export default {
    name: 'AboutView',
    components: {
      ConnectBlock
    },
    data() {
      return {

      }
    },
    mounted: function () {

    }
  }
</script>