<template>
  <div class="box" :style="cssVars">
    <div v-if="day">
      <p v-if="day.events" class="events">
        <!--<TimeBar class="info" :events="day.events"/>  -->

        <el-row v-for="e,i in day.events" :key="i" @click="select_event(e)" class="event">
          <el-col :span="6">
            <div class="calendar_num">
              <!--<b-img square  width="24" height="24" :alt="e.action.class" :src="'/actions/icons/' + e.action.class + '.png'"></b-img>-->
              <el-image v-if="e.code" style="width: 16px; height: 16px" :src="api + 'action/icon/' + e.code" fit="fill" />
              <strong class="time">{{Time(e.start)}}</strong>
            </div>
          </el-col>
          <el-col :span="18" style="overflow:hidden; word-wrap: break-word; word-wrap: break-word;">
            <div class="title">
              <strong v-if="e.title">{{e.title}}</strong>
              <strong v-else-if="e.action" class="alias">{{e.action.title.fr}}</strong>
              <strong v-else><em>No title</em></strong>
            </div>
                        
            <div class="desc">
              <small v-if="e.desc">{{e.desc}}</small>
              <small v-else-if="e.action && e.action.desc" class="alias">{{e.action.desc.fr}}</small>
              <small v-else><em>No desc</em></small>              
            </div>
          </el-col>
        </el-row>
      </p>

      <p v-else class="events">
        <!--<TimeBar class="info" events="[]"/>-->
        <em>Free day...</em>
      </p>
    </div>

    <div v-else>
      <h4> No data </h4>
    </div>
  </div>
</template>

<script>
//import TimeBar from '../components/TimeBar.vue'
export default {
    name: 'DataBox',
    props: {
        day: Object,
        active: Boolean
    },
    components: {
      //TimeBar
    },
    data() {
        return {
          api: process.env.VUE_APP_API
        }
    },
    methods: {
        Time: function (dttm) {
          var st = new Date(dttm)
          return ((st.getUTCHours() < 10) ? '0' : '') + st.getUTCHours() + ':' + ((st.getUTCMinutes() < 10) ? '0' : '') + st.getUTCMinutes();
        },
        Date: function (dttm)
        {
          var st = new Date(dttm)
          return st.getUTCDate();
        },
        displayed_date: function (date) {
          var dt = new Date(date);
          var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
          var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc']
          var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + '  ' + m[dt.getUTCMonth()];
          return str;
        },
        displayed_time: function (time) {
          var tm = new Date(time)
          var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
          var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
          return hr + ':' + min;
        },
        select_event: function(event)
        {
          this.$emit("selected", event)
        }
    },
    computed:
    {
      cssVars() {
        var vars = {};
        if(this.day)
        {
          vars['background-image'] = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><text opacity='0.1' x='50%' y='50%' fill='black' font-family='sans-serif' font-size='65vw' font-weight='bold' dominant-baseline='middle' text-anchor='middle'>`+this.day.date.getUTCDate()+`</text></svg>"`
          vars['opacity'] = this.active ? 0.95 : 0.5

          switch(this.day.date.getUTCDay())
          {
            case 6:
              {
                vars['background-color'] = '#FFD';
                break;
              }
            case 0:
              {
                vars['background-color'] = '#FDD';
                break;
              }
            default:
              {
                vars['background-color'] = '#FFF';
                break;
              }
          }          
        }

        return vars;
      }
    },
    mounted()
    {
        
    }
}
</script>
  
<style scoped>
.box
{
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1em;
  text-align: start;

  display: flex;
  flex-direction: column;
  overflow-y: visible;
}
.events
{
  flex: 1 1 auto;
  text-align: start;

  overflow-y: hidden;
  max-height: 150px;
}

.event
{
  line-break: auto;
  list-style:circle;
  border-bottom: 1px solid;
}
.info
{
  vertical-align: bottom;

}

.time
{
  font-size: 8px;
  padding: 0px;
  border: 0px;
  margin: 0px;
  font-weight: bold;
  color: #33E;
}
.title
{
  font-size: x-small;
  flex: 1 1 auto;
  text-align: start;
}

.desc
{
  font-size: xx-small;
  word-wrap: break-word;

}
  .alias
  {
      font:bolder;
      color: #33E;
      font-style: oblique;
  }
</style>