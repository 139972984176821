<template>
  <!--
  <span v-if="editable" class="root">
    <Label v-for="i in max" :key="i" textWrap="true" @tap="stars_num = i;">
      <FormattedString>
        <span v-if="i <= stars_num" text.decode="&#xf005;" color="#FD9" class="fas stars maxi"/>
        <span v-else text.decode="&#xf005;" color="#DDD" class="fas stars maxi"/>
      </FormattedString>
    </Label>
    
    <Label v-show="stars_num" text.decode="&#xf00d;" @tap="stars_num = 0" class="fas clear maxi"></Label>
  </span>
  -->


  <span v-if="editable" class="root">
      <el-icon v-for="i in max" :key="i" :color="i <= stars_num ? '#FA8' : '#EEE'" @tap="stars_num = i;"><Star /></el-icon>
      <el-icon v-show="stars_num" color="#F00" @tap="stars_num = 0"><Close /></el-icon>
  </span>

  <span v-else class="root">
    <el-icon v-for="i in max" :key="i" :color="i <= stars_num ? '#FA8' : '#EEE'"><Star /></el-icon>
  </span>
</template>

<script>
export default {
    name: "RatingStarsSelector",
    props: {
        stars: { type: Number, default: 0 },
        max: { type: Number, default: 5 },
        editable: { type: Boolean, default: false }
    },
    model: {
      prop: 'stars',
      event: 'refresh'
    },
    data() {
        return {
            stars_num: this.stars
        };
    },
    watch:
    {
      stars: function()
      {
        this.stars_num = this.stars; 
        this.$emit('refresh', this.stars_num); 
      }, 
      /*
      stars_num: function(s)
      {
        this.stars = s; 
        this.$emit('refresh', s);         
      }
      */
    }
  }
</script>

<style scoped>
.root
{
  padding: 0px;
  border: 0px;
  margin: 0px;
  text-align: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  width: auto;
}
.stars
{
  margin: 0px;
  padding: 0px;
  border: 0px;
}
.mini
{
  font-size: smaller;
}
.maxi
{
  font-size: 40px;
}
.clear
{
  color: #D00;
  text-align: right;
}
</style>