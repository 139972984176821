<template>
  <div class="cont">
    <router-link :to="link" v-slot="{ isExactActive }">
      <div :class="isExactActive ? 'btn_on button' : 'btn_off button'">
        
        <span class="large">
          <font-awesome-icon :icon="icon" style="font-size: 1.5em;" />
          <hr />
          <strong>{{ name }}</strong>        
        </span>

        <span class="small">
          <font-awesome-icon :icon="icon" style="font-size: 1.5em;" />

          <strong>{{ name }}</strong>
        </span>

      </div>
    </router-link>
  </div>
</template>

<script>
export default {
    name: 'LinkButton',
    props: {
      link: String,
      icon: String,
      name: String,
    },
    components: {
      
    },
    data() {
        return {

        }
    },
    methods:
    {

    }
}
</script>

<style scoped>


.button
{
  /*display: inline-table;*/


  padding: 1px;
  margin: 1px;
  box-shadow: 3px 3px 2px grey;
  font-family:Arial, Helvetica, sans-serif;
  font-size: small;
  border: solid 1px;
  border-radius: 8px;

  justify-content:flex-start;
}



.btn_on
{
  color: #000;
  background-color: #FFF;
}

.btn_off
{
  color: #FFF;
  background-color: #000;
}



@media (min-width: 480px)
{
  .cont
  {
    display: inline-flex;
    background-color: #F50;
    color: bisque;

  }  

  .cont .button
  {
    height: 60px;
    width: 60px;
  }

  .large
  {
    visibility: visible;
  }
  .small
  {
    /*visibility: hidden;*/
    display: none;
  }
}

/* Small screen */
@media (max-width: 480px)
{
  .cont
  {
    display:flex;
    background-color: #5A5;
    color: blue;
    max-width: 200px;
  }

  .cont .button
  {
    height: 30px;
    width: 80px;
    display:flex;
    overflow: hidden;
  }

  .large
  {
    /*visibility: hidden;*/
    display: none;
  }
  .small
  {
    visibility: visible;
  }
}
</style>