<template>
    <div class="border" >
        <slot :style="'opacity: ' + show ? '0.5;' : '1;'"></slot>

        <div v-show="show">
            <div v-if="count">
                <ol v-if="error_pack && count">
                    <li v-for="e,i in error_pack" :key="i">
                        <font-awesome-icon :icon="key2icon(i)" style="font-size: 1em;" />
                        <em>-{{ e }}</em>
                    </li>
                </ol>
            </div>
            <div v-else>
                <font-awesome-icon icon="mug-hot" style="font-size: 1em;" />
                <em>Done!</em>
            </div>
        </div>
    </div>
</template>
  
<script>
  export default {
      name: 'ClickButton',
      props: {
        error_pack: Object,
      },
      components: {
        
      },
      data() {
          return {
            count: 0,
            show: false
          }
      },
      methods:
      {
        key2icon: function(key)
        {
            switch(key)
            {
                case 'title': { return 'text-slash'; }
                case 'desc' : { return 'quote-left'; }
                default: { return 'triangle-exclamation'; }
            }
        }
      },
      watch:
      {
        error_pack: function()
        {
            this.count = Object.keys(this.error_pack).length;

            this.show = true;
            setTimeout(() => { this.show = false; }, 2000)
        }
      }
  }
</script>
  
  <style scoped>
  .border
  {
    display: inline-table;
    width: 100%;
    padding: 1px;
    margin: 1px;
    box-shadow: 3px 3px 2px grey;
    font-family:Arial, Helvetica, sans-serif;
    font-size: small;
    border: solid 1px;
    border-radius: 5px;
    color: black;
    background-color: white;
  }
  
  </style>