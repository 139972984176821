<template>
  <div>

    <el-dropdown>
      <span class="el-dropdown-link">
        <el-input v-model="search_action" :placeholder="action_selected ? action_selected.title.fr : 'Not selected'">
          <template #prepend v-if="action_selected">
            <el-image style="width: 24px; height: 24px" :src="API + 'action/icon/' + action_selected.code" fit="contain" />
          </template>
        </el-input>
      </span>
      <template #dropdown>
        <el-dropdown-menu v-if="actions_suggest">
          <el-dropdown-item v-for="action,i in actions_suggest" :key="i" @click="select_action(action)">
            <el-image style="width: 32px; height: 32px" :src="API + 'action/icon/' + action.code" fit="contain" />
            {{ action.title.fr }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'ActionSelector',
    components: {

    },
    props: ['modelValue'],
    emits: ['update:modelValue', 'selected'],
    data: function () {
    return {
      API: process.env.VUE_APP_API,
      ACTIONS: process.env.VUE_APP_ACTIONS, 
      count: 0,
      error_pack: {},
      search_action: '',
      actions_suggest: [],
      action_selected: null
    }
  },
    methods: {
      select_action(action) {
        //console.log("ACTION", action);
        this.action_selected = action;
        
        this.search_action = '';
        this.actions_suggest = [];
        this.$emit('selected', action);

        this.$emit('update:modelValue', action.code)
      },
      select_by_code(code)
      {
        if(code)
        {
          this.actions_suggest = [];
          axios.get(this.API + 'action/search/', { params: { code: code }, withCredentials: true })
          .then(response => {
            //this.actions_suggest = response.data;
            //console.log("Reponse", response.data);
            this.action_selected = response.data
          })
          .catch(e => console.log("ERR51: " + e));          
        }
      }
    },
    watch: {
      search_action: function(sa) {
        if(sa)
        {
          this.actions_suggest = [];
          axios.get(this.API + 'action/search/', { params: { text: this.search_action }, withCredentials: true })
          .then(response => {
            this.actions_suggest = response.data;
          })
          .catch(e => console.log("ERR46: " + e));          
        }
      },
      modelValue: function(c)
      {
        //console.log("Watch code change:", c)
        if(!c)
        {
          this.action_selected = null;
        }
        else
        {
          this.select_by_code(c)
        }
      }
    },
    mounted: function () {
      console.log("ActionSelector Mounted")
      this.select_by_code(this.modelValue)
    }
  }
</script>

<style scoped lang="css">
.line
{
  text-overflow: clip ellipsis;

}
</style>