<template>
    <div>
        <h2>Next:</h2>

        <el-timeline>
            <el-timeline-item v-for="e,i in next_events" :key="i" :timestamp="displayed_date(e.start)" placement="top">
                <DataLine :event="e"/>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
//import WeekTemplate from '../components/WeekTemplate.vue'
import DataLine from '@/components/DataLine.vue';
import axios from 'axios'

export default {
    name: 'WelcomeView',
    components: {
        //WeekTemplate
        DataLine
    },
    props: [],
    data: function () {
    return {
        api: process.env.VUE_APP_API,
        action: {},
        next_events: []
    }
    },
    methods:
    {
        displayed_date: function (date)
        {
            var dt = new Date(date);
            var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
            var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
            var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
            return str;
        },
        get_next_event: function()
        {
            axios.get(this.api + 'events/next', { params: {}, withCredentials: true })
            .then(response =>
            {
                this.next_events = response.data;
            })
            .catch(console.log("errrr"));
        }
        
    },
    computed:
    {

    },
    mounted()
    {
        this.get_next_event();
    }
}
</script>

<style scoped>
h1
{
    margin: 40px 0 0;
    color: #FCA;
}


</style>