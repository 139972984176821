<template>
    <div class="btn">
        <font-awesome-icon :icon="icon" class="center" style="font-size: 2em;" :style="'color: ' + (color ? color : '#FCC') + ';'"/>
        <br/>
        <hr/>
        <strong class="center">{{ name }}</strong>        
    </div>
</template>
  
<script>
  export default {
      name: 'ClickButton',
      props: {
        icon: String,
        name: String,
        color: String
      },
      components: {
        
      },
      data() {
          return {
  
          }
      },
      methods:
      {
  
      }
  }
</script>
  
  <style scoped>
  .btn
  {
    display: block;
    height: 60px;
    width: 60px;
    padding: 1px;
    margin: 1px;
    box-shadow: 3px 3px 2px grey;
    font-family:Arial, Helvetica, sans-serif;
    font-size: small;
    border: solid 1px;
    border-radius: 10px;
  }

  .center
  {
    align-items: center;

  }
  

  
  </style>