<template>
    <div>
        <div v-if="!databases">
            <font-awesome-icon icon="circle-info" />
            <strong>Database list unaiviable: Only defaut DB readonly.</strong>
            <el-button @click="update_db">Update</el-button>
        </div>
        <div v-else-if="databases.length == 1">
            <font-awesome-icon icon="circle-info" />
            <strong>No user connected. Only defaut DB readonly.</strong>
        </div>  
        <div v-else>
            <div v-if="choose" style="align-content: space-between;">
                <el-radio-group v-model="radio" text-color="#000" @change="refresh">
                    <span
                        v-for="db,i in databases"
                        :key="i"
                        :style="'background-image: linear-gradient(90deg, #FFF 0%, ' + db.color + ' 100%); line-height: 20px;'">
                        
                        <el-radio :label="db.id">
                            {{ db.name }}
                        </el-radio>
                    </span>
                </el-radio-group>
            </div>
            <div v-else style="align-content: space-between;">

                <span v-for="db,i in databases" :key="i" :style="'border-color:' + db.color + ';'">
                    <el-switch v-model="db.actived" @change="refresh" :style="'--el-switch-on-color: ' + db.color + '; --el-switch-off-color: #AAA'" />
                    {{ db.name }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

  export default ({
    name: 'DataBaseSelector',
    props: {
        dbs: { type: Array },
        choose: { type: Boolean },
        old_database: { type: String }
    },
    emits: ['update:dbs', 'new_database' /*,'update:actives'*/],
    data()
    {
      return {
        radio: null,
        databases: global.databases
      }
    },
    methods:
    {
        update_db: function()
        {
            this.databases = global.databases;
        },
        refresh: function()
        {
        //console.log("REFRESH/////", this.databases)
            this.$emit('update:dbs', this.databases);
            this.$emit('new_database', this.radio);
        },
        check_log: function()
        {
            axios.get(process.env.VUE_APP_API, { withCredentials: true })
            .then(response =>
            {
                console.log("Check at */*", response);
                this.user = response.data.user;

                var databases = [{
                        name: 'Default',
                        id: 'default',
                        color: '#000',
                        read: true,
                        write: false,
                        actived: true
                    }]

                // SetDatabase:
                if(this.user)
                {
                    databases.push({
                        name: 'Main DB',
                        id: this.user._id,
                        color: '#A0A',
                        read: true,
                        write: true,
                        actived: true
                    });

                    this.user.groups.forEach(group => { databases.push(group) });
                }

                global.user = this.user;
                global.databases = databases;
                this.databases = databases;
            })
            .catch(function(err)
            {
                console.log("Error of post", err);
            });
        }
    },
    watch:
    {
       databases: function()
       {
            this.refresh();
       },
       radio: function()
       {
            this.refresh();
       }
    },
    updated()
    {
        if(this.choose && this.old_database)
        {
            this.radio = this.old_database;
            //console.log("Radio:" + this.radio)
        }
    },
    mounted()
    {
        this.check_log();
        this.databases = this.dbs;
    }
  })
  </script>
  
  <style>
  .blk
  {
    opacity: 0.5;
    border-style: solid;
    border-radius: 5%;
    border-bottom: 2px;
    border-right: 2px;
  }
  
  </style>