<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="3"><div class="day_title" />Mon:</el-col>
      <el-col :span="3"><div class="day_title" />Tue:</el-col>
      <el-col :span="3"><div class="day_title" />Wed:</el-col>
      <el-col :span="3"><div class="day_title" />Thu:</el-col>
      <el-col :span="3"><div class="day_title" />Fri:</el-col>
      <el-col :span="3"><div class="day_title" />Sat:</el-col>
      <el-col :span="3"><div class="day_title" />Sun:</el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="3" v-for="d,i in week_labels" :key="i">
        <div v-if="week_template[d]">
          <el-button @click="del(d)" type="danger" plain>Del</el-button>
          <div class="hour">{{ week_template[d].start }}</div>
          <div :style="'height=' + BoxHeight('07:00', week_template[d].start) + ' px;'" class="free">*</div>
          <div :style="'height=' + BoxHeight(week_template[d].start, week_template[d].end) + ' px;'" class="busy">***</div>
          <div :style="'height=' + BoxHeight(week_template[d].end, '21:00') + ' px;'" class="free">*</div>
          <div class="free">{{ week_template[d].end }}</div>
        </div>

        <div v-else>
          <el-button @click="add(d)" type="success" plain>Add</el-button>
          <div class="hour">--:--</div>
          <div :style="'height=' + BoxHeight('07:00', '21:00') + ' px;'" class="free">*</div>
          <div class="hour">--:--</div>
        </div>
      </el-col>
    </el-row>

    <!--
    <el-row :gutter="20">
      <el-col :span="3" v-for="d,i in week_template" :key="i">
        <div v-if="d">
          <el-button @tap="del(i)" type="success">Del</el-button>
          <div class="hour">{{ d.start }}</div>
          <div :style="'height=' + BoxHeight('07:00', d.start) + ' px;'" class="free">*</div>
          <div :style="'height=' + BoxHeight(d.start, d.end) + ' px;'" class="busy">***</div>
          <div :style="'height=' + BoxHeight(d.end, '21:00') + ' px;'" class="free">*</div>
          <div class="free">{{ d.end }}</div>
        </div>

        <div v-else>
          <el-button @tap="add(i)" type="danger">Add</el-button>
          <div class="hour">--:--</div>
          <div :style="'height=' + BoxHeight('07:00', '21:00') + ' px;'" class="free">*</div>
          <div class="hour">--:--</div>
        </div>
      </el-col>
    </el-row>
    -->
  </div>

</template>

<script>
//import TimeBar from '../components/TimeBar.vue'

export default {
    name: 'WeekTemplate',
    props: {
        week: Object
    },
    components: {
      //TimeBar
    },
    data()
    {
      return {
        week_labels: ['mon', 'tue', 'wen', 'thu', 'fri', 'sat', 'sun' ], 
        //week_template: [ null, null, {start: '12:00', end: '15:00'}, null, null, null, null ]
        week_template: { mon: { start: '09:30', end: '18:00'}, sat: { start: '08:00', end: '16:00' }}
      }
    },
    methods: {
        Time: function (dttm) {
          var st = new Date(dttm)
          return ((st.getUTCHours() < 10) ? '0' : '') + st.getUTCHours() + ':' + ((st.getUTCMinutes() < 10) ? '0' : '') + st.getUTCMinutes();
        },
        Date: function (dttm)
        {
          var st = new Date(dttm)
          return st.getUTCDate();
        },
        BoxHeight: function(from, to)
        {
          var from_minutes = 60 * (10*parseInt(from[0]) + parseInt(from[1])) + 10*parseInt(from[3]) + parseInt(from[4]);
          var to_minutes = 60 * (10*parseInt(to[0]) + parseInt(to[1])) + 10*parseInt(to[3]) + parseInt(to[4]);
          return (to_minutes - from_minutes) * 0.25;
        },
        add: function(key)
        {
          // Display time select modal
          console.log("Add:", key)
          this.week_template[key] = { start: '08:30', end: '18:00'}; // pour le moment
        },
        del: function(key)
        {
          console.log("Del:", key)
          //this.week_template[index] = null;
          delete this.week_template[key]
        }
    },
    computed:
    {

    },
    mounted()
    {
      if(this.week) { this.week_template = Object.assign({}, this.week); }
    }
}
</script>
  
<style scoped>
.box
{
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.day_title
{
  font-weight: bold;
  font-size: 10px;
}

.free
{
  background-color: #FFC;
}
.busy
{
  background-color: #FC5;
  border-radius: 5px;
  border-color: black;
  border-width: 2px;
  box-shadow: 10px;
}

.hour
{
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}
</style>