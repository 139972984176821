<template>
    <div class="block" :style="'background-color:' + color + ';'">
        <el-row>
            <el-col :xs="24" :span="3">
                <div class="title"> {{title}} </div> 
            </el-col>
            <el-col :xs="24" :span="21">
                <slot :style="'border: 1px #FEE; width: 100%; background-color:' + color + ';'"> </slot>
            </el-col>
        </el-row>

        <small v-if="error" style="color: #F00"><em>{{ error }}</em></small>
    </div>
</template>


<script>
export default {
name: "FormWidget",
components:
{

},
props: [ 'title', 'color', 'error' ],
data()
{
  return {
  }
},
methods:
{
    displayed_time: function (time)
    {
        var tm = new Date(time)
        var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
        var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
        return hr + ':' + min;
    }
}
}

</script>



<style>
@font-face
{
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}


@media (min-width: 480px)
{
  .cont
  {
    display: inline-flex;
    background-color: #F50;
    color: bisque;

  }  
}

/* Small screen */
@media (max-width: 480px)
{
  .cont
  {
    display:flex;
    background-color: #5A5;
    color: blue;
    max-width: 200px;
  }
}



.title
{
    text-align: left;
    font-style: oblique;
    color: black;
}

.block
{
    border: solid 1px;
    border-color: #EFE;
    border-radius: 5px;
    width: 100%;
    box-shadow: 2px 2px grey;
    padding: 5px;
    margin: 5px;
}

.lcd
{
    text-align: center;

    display: inline;

    vertical-align: baseline;

    font-size: 10px;
    font-weight: bold;
    font-family: LCD;

    width: 100%;
    padding: 5px;
}


</style>