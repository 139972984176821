<template>
    <div>
        <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
                <el-row class="back_now">
                    <el-col :span="6">
                        <DateBlock :date="now"/>
                    </el-col>
                    <el-col :span="18">
                        <span class="lcd"> {{displayed_time(now)}} </span>
                    </el-col>
                </el-row>
                <hr/>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">  
                <el-row v-if="next.length" class="back_next" style="display: block;">
                    <div v-for="e,i in next" :key="i" style="text-align: left;">
                        <span class="list_desc">{{displayed_date(e.start)}}</span>
                        -                       
                        <el-image v-if="e.code" style="width: 16px; height: 16px;" :alt="e.code" :src="api + 'action/icon/' + e.code" />
                        <!--<el-image style="width: 100px; height: 100px" :src="url" :fit="fit" />-->

                        <span v-if="e.title" class="list_title">{{e.title}}</span>
                        <span v-else-if="e.action && e.action.title" class="list_title alias">{{e.action.title.fr}}</span>
                        <span v-else class="list_title">No title</span>
                    </div>                        
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>
  
<script>
var _events = require('@/lib/events');

import DateBlock from './DateBlock.vue';

export default {
    name: 'TimeBlock',
    components: {
        DateBlock
    },
    data() {
        return {
            api: process.env.VUE_APP_API,
            now: new Date(),
            event: { title: 'test', desc: 'blabla' }, 
            next: []
        }
    },
    methods: {        
        displayed_date: function(date) {
            var dt = new Date(date);
            var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
            var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
            var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];// + ' ' + dt.getUTCFullYear();
            return str;
        },
        displayed_time: function(time) {
            var tm = new Date(time)
            var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
            var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
            return hr + ':' + min;
        },
        next_events: function()
        {
            _events.next().then(next => this.next = next)
        }
    },
    mounted: function() {
        setInterval(function()
        {
            this.now = new Date();
        }.bind(this), 10000);
        this.next_events();
    }
}
</script>
  
<style scoped>
  .left {
    text-align: left;
  }

  hr{
    margin: 1px;
  }

  @font-face {
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }

  .lcd
  {
    text-align: center;

    display: table-cell;

    vertical-align: baseline;
    
    font-size: 50px;
    font-weight: bold;
    font-family: LCD;

    width: 100%;
    padding: 5px;
  }

.alias
{
    font:bolder;
    color: #33E;
    font-style: oblique;
}

.back_now
{
    background-color: gainsboro;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><text opacity='0.1' x='50%' y='50%' fill='black' font-family='sans-serif' font-size='50px' font-weight='bold' dominant-baseline='middle' text-anchor='middle'>Now</text></svg>");
    opacity: 0.9;
    min-height: 50px;
}

.back_next
{
    background-color: gainsboro;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'><text opacity='0.1' x='50%' y='50%' fill='black' font-family='sans-serif' font-size='50px' font-weight='bold' dominant-baseline='middle' text-anchor='middle'>Next</text></svg>");
    opacity: 0.9;
    min-height: 50px;

}

.list_title
{
    font-weight: bold;
    font-size: 11px;
}

.list_desc
{
    font-style: italic;
    font-size: 10px;
    overflow: hidden;
}


</style>