<template>
    <div>
        <h1>Default content for action</h1>

        <p>Cette action ne comporte pas (encore) de plugin spécifique</p>

        <p>Si vous pensez que l'action le meriterais veuillez nous faire part de vos idées concernant les données stockables</p>
        

    </div>
</template>

<script>
//import { IonItem, IonLabel, IonTextarea } from '@ionic/vue';

export default {
    name: "ActionDefault",
    components:
    {
        //IonItem, IonLabel, IonTextarea
    },
    data() {
      return {
        test: "ok!"
      }
    },
    methods:
    {
        set_action: function()
        {
            console.info("Setting........");
        }
    },
    mounted()
    {
        //console.log('Contenu par default MOUNTED')
    }
}
</script>

<style>
h1
{
    font-size: larger;
    font: #F00;
}


</style>