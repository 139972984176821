<template>
    <div style="width: 100%;">
        
        <el-button-group class="ml-4" size="small">
            <el-button type="default" @click="SetDaysDelay(-30);" :disabled="delay < 30">
                <el-icon><DArrowLeft /></el-icon>
                30
            </el-button>
            <el-button type="default" @click="SetDaysDelay(-7);" :disabled="delay < 7">
                <el-icon><DArrowLeft /></el-icon>
                7
            </el-button>
            <el-button type="default" @click="SetDaysDelay(-1);" :disabled="delay < 1">
                <el-icon><DArrowLeft /></el-icon>
                1
            </el-button>
        </el-button-group>

        <el-button-group size="small">
            <el-button type="default" @click="SetDaysDelay(0);" :disabled="delay === 0">
                <el-icon><Switch /></el-icon>
                Same
            </el-button>
        </el-button-group>

        <el-button-group class="ml-4" size="small">
            <el-button type="default" @click="SetDaysDelay(1);">
                <el-icon><DArrowRight /></el-icon>
                1
            </el-button>
            <el-button type="default" @click="SetDaysDelay(7);">
                <el-icon><DArrowRight /></el-icon>
                7
            </el-button>
            <el-button type="default" @click="SetDaysDelay(30);">
                <el-icon><DArrowRight /></el-icon>
                30
            </el-button>
        </el-button-group>


        <div v-show="same">
            <el-row>
                <el-col :span="24" style="text-align: start;">
                    <strong style="display: left;">{{ displayed_date(start_iso) }}</strong>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="4">
                    <span class="lcd">{{ TimeString(value[0]) }}</span>
                </el-col>
                <el-col :span="16">
                    ...
                </el-col>
                <el-col :span="4">
                    <span class="lcd">{{ TimeString(value[1]) }}</span>
                </el-col>
            </el-row>    
            <el-row>
                <el-col :span="24">
                    <el-slider v-model="value" range show-stops :min="0*4" :max="24*4" :show-tooltip="false" />
                </el-col>
            </el-row>      
        </div>
        <div v-show="!same">
            <el-row>
                <el-col :span="24" style="text-align: start;">
                    <strong style="display: left;">{{ displayed_date(start_iso) }}</strong>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="4">
                    <span class="lcd">{{ TimeString(value[0]) }}</span>
                </el-col>
                <el-col :span="16">
                    ...
                </el-col>
                <el-col :span="4">
                    <!--<span class="lcd">{{ TimeString(value[1]) }}</span>-->
                </el-col>
            </el-row>    
            <el-row>
                <el-col :span="24">
                    <el-slider v-model="value[0]" show-stops :min="0*4" :max="24*4" :show-tooltip="false" />
                </el-col>
            </el-row> 

            <el-row>
                <el-col :span="24" style="text-align: start;">
                    <strong style="display: left;">{{ displayed_date(end_iso) }}</strong>
                    <em> + {{ delay }} days</em>
                </el-col>
            </el-row>    
            <el-row>
                <el-col :span="4">
                    <!--<span class="lcd">{{ TimeString(value[0]) }}</span>-->
                </el-col>
                <el-col :span="16">
                    ...
                </el-col>
                <el-col :span="4">
                    <span class="lcd">{{ TimeString(value[1]) }}</span>
                </el-col>
            </el-row>                

            <el-row>
                <el-col :span="24">
                    <el-slider v-model="value[1]" show-stops :min="0*4" :max="24*4" :show-tooltip="false" />
                </el-col>
            </el-row>   
        </div>
        

    </div>
</template>
  
<script>

export default {
    name: 'TimeSelector',
    components: {

    },
    props: ['modelValue'],
    emits: ['update:modelValue', 'selected'],
    data: function ()
    {
        return {
            value: [50, 50],
            delay: 0,
            same: true        
        }
    },
    methods:
    {
        TimeString: function(value)
        {
            var hour = Math.floor(value / 4);
            var min = (value % 4) * 15 > 10 ? (value % 4) * 15 : '0' + (value % 4) * 15;

            //this.$emit('update:modelValue', action.code)

            return hour + ':' + min;
        },
        ToISO: function(value, date)
        {
            var hour = Math.floor(value / 4);
            var min = (value % 4) * 15 > 10 ? (value % 4) * 15 : '0' + (value % 4) * 15;

            //var dt = new Date(this.modelValue);
            var dt = new Date(date);
            dt.setUTCHours(hour);
            dt.setUTCMinutes(min);

            return dt.toISOString();
        },
        ToValue: function(iso)
        {
            var dt = new Date(iso)
            return 4 * dt.getUTCHours() + Math.floor(dt.getUTCMinutes() / 15) // Attention arrondi au quart d'heure par default
        },
        IsSameDay: function()
        {
            var st = this.start_iso.slice(0, 10);
            var nd = this.end_iso.slice(0, 10);
            return st == nd;
        },
        displayed_date: function (date)
        {
            var dt = new Date(date);
            var d = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
            var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
            var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()] + ' ' + dt.getUTCFullYear();
            return str;
        },
        SetDaysDelay: function(days)
        {
            var end = new Date(this.modelValue.end);
            var dt = new Date(this.modelValue.start);

            if(days === 0)
            {
                this.delay = 0;
            }
            else
            {
                this.delay = this.delay + days;
            }

            console.log("********** Days:" + days + " -- Delay:" + this.delay)
            
            dt.setUTCDate(dt.getUTCDate() + this.delay);
            dt.setUTCHours(end.getUTCHours());
            dt.setUTCMinutes(end.getMinutes());
            

            this.$emit('update:modelValue', { start: this.start_iso, end: dt.toISOString() });
            return;
        }
    },
    computed:
    {
        start_iso: function()
        {
            return this.ToISO(this.value[0], this.modelValue.start);
        },
        end_iso: function()
        {
            return this.ToISO(this.value[1], this.modelValue.end);
        },
    },
    watch:
    {
        'modelValue.start': function()
        {
            this.same = this.IsSameDay();

            this.value[0] = this.ToValue(this.modelValue.start);
            //this.value[1] = this.ToValue(this.modelValue.end);
        },
        'modelValue.end': function()
        {
            this.same = this.IsSameDay();

            //this.value[0] = this.ToValue(this.modelValue.start);
            this.value[1] = this.ToValue(this.modelValue.end);
        },
        start_iso: function()
        {
            this.$emit('update:modelValue', { start: this.start_iso, end: this.end_iso });
        },
        end_iso: function()
        {
            this.$emit('update:modelValue', { start: this.start_iso, end: this.end_iso })
        }



    },
    mounted()
    {
        this.same = this.IsSameDay();
        console.log("Model: ----- ", this.modelValue);

        //this.value = [this.ToValue(this.modelValue.start), this.ToValue(this.modelValue.end)]

        this.value[0] = this.ToValue(this.modelValue.start);
        this.value[1] = this.ToValue(this.modelValue.end);
        //this.value = [this.ToValue(this.modelValue.start), this.ToValue(this.modelValue.end)]
        //console.log("Mount to...", this.value)
    },
    /*
    updated()
    {
        this.value = [this.ToValue(this.modelValue.start), this.ToValue(this.modelValue.end)]
        console.log("Update to...", this.value)

    }
    */
}
</script>

<style scoped lang="css">

@font-face
{
    font-family: 'LCD';
    src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

.lcd
{
    text-align: center;
    color: #FA0;
    font-size: 15px;
    font-weight: bolder;
    font-family: LCD;
}

.line
{
    display: inline-flex;
}
</style>