<template>
    <div>
      <h1>Next tasks ...</h1>
      <el-collapse accordion style=" text-shadow: black;">
        <el-collapse-item name="Ret 30+">
            <template #title>
                <strong><em style="color: #F00;">{{tasks_late30.length}}</em> - Retard de plus d'un mois</strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #F00;">
                <span v-for="tsk,j in tasks_late30" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

        <el-collapse-item name="Ret 7+">
            <template #title>
                <strong><em style="color: #FA0;">{{tasks_late7.length}}</em> - Retard jusqu'a un mois</strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #FA0;">
                <span v-for="tsk,j in tasks_late7" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

        <el-collapse-item name="Ret 1+">
            <template #title>
                <strong><em style="color: #FF0;">{{tasks_late1.length}}</em> - Retard jusqu'à une semaine</strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #FF0;">
                <span v-for="tsk,j in tasks_late1" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

        <el-collapse-item name="Now">
            <template #title>
                <strong><em style="color: #0F0;">{{tasks_inprogress.length}}</em> - En cours</strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #0F0;">
                <span v-for="tsk,j in tasks_inprogress" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

        <el-collapse-item name="En avance 1 - 7 jours">
            <template #title>
                <strong><em style="color: #0FF;">{{tasks_adv1.length}}</em> - jusqu'à un semaine d'avance</strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #0FF;">
                <span v-for="tsk,j in tasks_adv1" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

        <el-collapse-item name="En avance 7 - 30 jours">
            <template #title>
                <strong><em style="color: #0AF;">{{tasks_adv7.length}}</em> - jusqu'a un mois d'avance </strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #0AF;">
                <span v-for="tsk,j in tasks_adv7" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

        <el-collapse-item name="En avance 30 jours">
            <template #title>
                <strong><em style="color: #00F;">{{tasks_adv30.length}}</em> - Plus d'un mois d'avance </strong>
            </template>
            <div style="border: 1px solid; border-radius: 10px; border-color: #00F;">
                <span v-for="tsk,j in tasks_adv30" :key="j">
                    <DataLine :event="tsk" :selected="false" @click="LaunchModal(tsk)" @done="close_modal()" />
                </span>
            </div>
        </el-collapse-item>

      </el-collapse>
  
      SHOW: {{ show }}
      <EditEvent v-show="show" :event_i="evt_edited" @done="CloseModal"/>
    </div>
</template>
  
  
<script>
  var _events = require('@/lib/events');

    import DataLine from '../components/DataLine.vue'
    import EditEvent from '../components/EditEvent.vue';
  
    //import DateBlock from '@/components/DateBlock.vue';
  
    const
      data = {
        now: new Date(),
        show: false,
        database: null,
        tasks: [],
  
        error_pack: {},
        cli_date: new Date(),
  
        cursor: {},
        drawer: false,
        evt_edited: null,
        plc_edited: null,

        tasks_late30: [],
        tasks_late7: [],
        tasks_late1: [],
        tasks_inprogress: [],
        tasks_adv1: [],
        tasks_adv7: [],
        tasks_adv30: []

      };
  
    export default {
      name: 'NextView',
      components: {
        EditEvent,
        DataLine,
        //DateBlock,
        //TimeBar,
          
      },
      data() {
        return data;
      },
      methods:
      {
        Refresh: function()
        {
          this.tasks = [];

          var start = new Date("2020-01-01T00:00:00.000Z");
          var end = new Date("2025-10-26T10:20:15.146Z");

          _events.undo({ start: start, end: end }).then(tasks => { this.tasks = tasks; this.Classification(); } ).catch(e => console.log("abcdef:"+e))
        },
        Classification: function()
        {
            this.tasks_inprogress = [];
            this.tasks_adv1 = [];
            this.tasks_adv7 = [];
            this.tasks_adv30 = [];
            this.tasks_late1 = [];
            this.tasks_late7 = [];
            this.tasks_late30 = [];


            for(var i=0; i<this.tasks.length; i++)
            {
              var tsk = this.tasks[i]

              var start = new Date(tsk.start);
              var end = new Date(tsk.end);

              var start_delta = this.DaysTo(start, this.now);
              var end_delta = this.DaysTo(end, this.now);
              //console.log("St delta: " + start_delta + " - nd delta: " + end_delta);

              tsk.delta = { start: start_delta, end: end_delta };

              if(start_delta > 0 && end_delta < 0) { this.tasks_inprogress.push(tsk); }

              if(end_delta > 0 && end_delta < 7) { this.tasks_late1.push(tsk); }
              if(end_delta > 7 && end_delta < 30) { this.tasks_late7.push(tsk); }
              if(end_delta > 30) { this.tasks_late30.push(tsk); }

              if(start_delta < 0 && start_delta > -7) { this.tasks_adv1.push(tsk); }
              if(start_delta < -7 && start_delta > -30) { this.tasks_adv7.push(tsk); }
              if(start_delta < -30) { this.tasks_adv30.push(tsk); }
            }
        },
        DaysTo: function(d1, d2)
        {
            var days = parseInt((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
            
            return days;
        },
        Locate(cursor) {
          this.cursor = cursor;
          //console.log("Locate cursor:", cursor)
        },
        new_task: function(date)
        {
          //console.log("DATE:" + date)
          //var dt = new Date()
  
          this.evt_edited = {
            _id: null,
            title: "",
            desc: "",
            start: date,
            end: date,
            alarm: "1975-01-01T00:00:00.000Z",
            task: { tasked: false, priority: 0, progress: 0, finish: null },
            data: {},
            modified: null,
            created: null,
            mode: 0
          }
        },
        new_place: function(geo)
        {
          //console.log("DATE:" + date)
          //var dt = new Date()
  
          console.log("Geo > ", geo)
          this.plc_edited = {
            _id: null,
            name: "",
            alias: "",
            address: { street: '', city: '', country: '', lon: 0, lat: 0 },
            actions: [],
            mode: 0
          }
        },
        LaunchModal: function(tsk)
        {
          console.log("EVENT SELECTED ...........................", tsk);
          this.evt_edited = tsk;
          this.show = true;
        },
        CloseModal: function(arg)
        {
          this.modal = false;
          this.Refresh();
          console.log("Close Modal ARG:", arg)
        }
      },
      mounted()
      {
        console.log("NextTasksView");
        this.Refresh();
      }
    }
  </script>
  
  <style scoped lang="css">
    @font-face {
      font-family: 'LCD';
      src: url('../../public/fonts/DIGITALDREAM.ttf') format('truetype');
      /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
    }
  
    .today
    {
      background-color: #AFA;
      border: 1px solid;
      box-shadow: 2px 2px;
      border-radius: 10px;
      margin: 3px;
      padding: 1px;
    }
  
    .other
    {
      background-color: white;
      border-bottom: 1px solid;
      box-shadow: 1px 1px;
      border-radius: 10px;
      margin: 3px;
      padding: 1px;
    }
  </style>