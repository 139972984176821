<template>
    <div class="about">
      <h1>This is an debug page</h1>

      <FormWidget title="ActionExec" color="#FFF">
        <div style="border: solid 1px; border-radius: 5px;">
          <ActionExec action="Shop" @new_metadata="Refresh" :parent_event="event" />

          <em>Result: {{ result }}</em>          
        </div>
      </FormWidget>



    </div>
  </template>
  
  <script>
    //import ConnectBlock from '@/components/ConnectBlock.vue';
    import ActionExec from '@/components/ActionExec.vue'
    import FormWidget from '@/widgets/FormWidget.vue'


    export default {
      name: 'DebugView',
      components: {
        ActionExec,
        FormWidget
      },
      data() {
        return {
          result: {},
          event:
          {
            id: '',
            title: 'Test fitness',
            desc: 'Test Fitness',
            start: '2023-04-24T14:00:00.000Z',
            end: '2023-04-24T16:00:00.000Z',
            code: 'Fitness',
            action:
            {
              code: 'Fitness',
              title: {},
              desc: {},
              count: -1
            },
            task:
            {
              tasked: false,
              progress: 0,
              priority: 0,
              finish: null
            },
            place: null
          },

          day: null,

          path: '',

          wait_dblist: false,

          dt: { start: "2023-07-01T14:00:00.000Z", end: "2023-07-02T18:00:00.000Z" }
        }
      },
      methods:
      {
        Refresh: function(r)
        {
          this.result = r;
        }
      },
      mounted()
      {

      }
    }
  </script>