import axios from 'axios';

var __range = { start: null, end: null };
//var __database = 'default';

export function load(date_range)
{
    if (date_range) { __range = date_range; }

    return new Promise((resolve, reject) =>
    {
        axios.get(process.env.VUE_APP_API + 'events/', { params: __range, withCredentials: true })
        .then(response =>
        {
            var ret = response.data;
            //__database = ret.database;
            
            resolve(sort_calendar(inject_actions(inject_rules(ret.rules, ret.events, __range)), __range));
        })
        .catch((e) => { console.log("Axios.get err:" , e); reject(); });
    })
}

export function load_multiDB(range, databases)
{
    return new Promise((resolve, reject) =>
    {
      //console.log(" Range:", range)
      if(!range) { reject(); }

      var reqs = [];
      var all_events = [];
      var all_rules = [];

      databases.forEach(db =>
      {
        //console.log("Request for DB:" + db + " Range:", range)
        var p = axios.get(process.env.VUE_APP_API + 'events/', { params: { start : range.start, end: range.end, database: db }, withCredentials: true })
        p.then(response =>
        {
          all_events = all_events.concat(response.data.events);
          all_rules = all_rules.concat(response.data.rules);
        });
        p.catch((e) => { console.log("Ressource inaccessible:", e); });
        reqs.push(p)
      });

      Promise.allSettled(reqs).then(() =>
      {
        //console.log("Finished! Events:" + all_events.length + " rules:" + all_rules.length)
        resolve(sort_calendar(inject_actions(inject_rules(all_rules, all_events, range)), range));
      });
    })
}


export function undo(date_range)
{
    if (date_range) { __range = date_range; }

    return new Promise((resolve, reject) =>
    {
      axios.get(process.env.VUE_APP_API + 'tasks/', { params: __range, withCredentials: true })
      .then(response =>
      {
        resolve(inject_actions(response.data));
      })
      .catch((e) => { console.log("Axios.get err:" , e); reject(); });
    })
}

export function next()
{
  return new Promise((resolve, reject) =>
  {
    axios.get(process.env.VUE_APP_API + 'events/next', { params: { number: 5 }, withCredentials: true })
    .then(response =>
    {
      var events = response.data;
      //__database = ret.database;

      resolve(events);
    })
    .catch((e) => { console.log("Axios.get err:" , e); reject(); });
  })
}

// ### Ajouter un triage par heure...
function sort_calendar(raw_calendar, range)
{
  var calendar_sorted = [];

  var s = Math.floor(new Date(range.start).getTime() / (24 * 60 * 60 * 1000));
  var e = Math.floor(new Date(range.end).getTime() / (24 * 60 * 60 * 1000));

  for (var i = s; i <= e; i++)
  {
    var offset = new Date();
    offset.setTime(24 * 60 * 60 * 1000 * i);

    var events = [];
    var holiday = false;

    for (var c = 0; c < raw_calendar.length; c++)
    {
      var st = new Date(raw_calendar[c].start);

      if ((offset.getUTCDate() == st.getUTCDate()) && (offset.getUTCMonth() == st.getUTCMonth()) && (offset.getUTCFullYear() == st.getUTCFullYear()))
      {
        events.push(raw_calendar[c]);
        if(raw_calendar[c].rule?.holiday) { holiday = true; }
      }
    }

    var color = '#000';
    if (offset.getUTCDay() == 6) { color = '#FA0'; }
    else if (offset.getUTCDay() == 0) { color = '#F00'; }

    if(holiday) { color = '#FF9'; }

    calendar_sorted.push({
      date: offset,
      events: events,
      color: color,
      holiday: holiday
    });
  }

  return calendar_sorted;
}

function inject_rules(rules, raw_calendar, range)
{       
  //console.log("RANGE:", range)
  var week_list = [ 'sun', 'mon', 'tue', 'wen', 'thu', 'fri', 'sat'];

  rules.forEach(rule =>
  {
    let first = new Date(rule.first);
    let last = new Date(rule.last);

    if(rule.week)
    {
      for(let d = new Date(range.start); d <= new Date(range.end); d.setUTCDate(d.getUTCDate() + 1))
      {
        let times = rule.week[week_list[d.getUTCDay()]]
        if(times)
        {
          let t_start = times.start.split(':');
          let t_end = times.end.split(':');
          let start = new Date(d).setUTCHours(t_start[0], t_start[1]);
          let end = new Date(d).setUTCHours(t_end[0], t_end[1]);

          if(start >= first && end <= last) //if(start >= first && end <= last)
          {
            var month = ((d.getUTCMonth()+1) > 9 ? '' : '0') + (d.getUTCMonth()+1);
            var day = (d.getUTCDate() > 9 ? '' : '0') + d.getUTCDate();
            var dt_str = d.getUTCFullYear() + '-' + month + '-' + day;

            if(!(rule.except && rule.except.includes(dt_str)))
            {
              var event =
              {
                _id: null,
                title: rule.title,
                desc: rule.desc,
                start: start,
                end: end,
                task: { tasked: false },
                rule: rule,
                code: rule.code,
                place: rule.place,
                database: rule.database
              }
              raw_calendar.push(event)                  
            }
              
          }
        }
      }
    }

    // Yearly rep
    else if(rule.year)
    {
      if(rule.year.fixed) //rule.week[week_list[cal.date.getUTCDay()]])
      {
        for(var d = new Date(range.start); d <= new Date(range.end); d.setUTCDate(d.getUTCDate() + 1))
        {
          let start = new Date(d); start.setUTCHours(0,0);
          let end = new Date(d); end.setUTCHours(0,0);

          let dt = rule.year.fixed;
          if(start >= first && end <= last && dt.day == start.getUTCDate() && dt.month == start.getUTCMonth())
          {
            let event =
            {
              _id: null,
              title: rule.title,
              desc: rule.desc,
              start: start,
              end: end,
              task: { tasked: false },
              rule: rule,
              code: rule.code,
              place: rule.place,
              database: rule.database
            }

            raw_calendar.push(event)                  
          }
        }
      }
    }

  });

  //console.log("Generated Cals:", sorted_calendar);
  return raw_calendar;
}

function inject_actions(events)
{
  events.forEach(evt =>
  {
    if(evt.code)
    {
      if(localStorage.getItem(evt.code))
      {
        // Present yet
        evt.action = JSON.parse(localStorage.getItem(evt.code))
      }
      else
      {
        // Dddl
        axios.get(process.env.VUE_APP_API + 'action/search/', { params: { code: evt.code }, withCredentials: true })
        .then(response =>
        {
          var ret = response.data;
          localStorage.setItem(evt.code, JSON.stringify(ret))

          evt.action = ret;
        });

      }
    }
  });
    
  return events;
}


export function displayed_date(date)
{
    var dt = new Date(date);
    var d = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
    var m = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'];
    var str = d[dt.getUTCDay()] + ' ' + dt.getUTCDate() + ' ' + m[dt.getUTCMonth()];
    return str;
}

export function displayed_time(time)
{
    var tm = new Date(time)
    var hr = (tm.getUTCHours() <= 9 ? '0' : '') + tm.getUTCHours();
    var min = (tm.getUTCMinutes() <= 9 ? '0' : '') + tm.getUTCMinutes();
    return hr + ':' + min;
}

export function is_same_day(d1, d2)
{
    return d1.getUTCDate() == d2.getUTCDate() &&  d1.getUTCMonth() == d2.getUTCMonth() && d1.getUTCFullYear() == d2.getUTCFullYear();
}