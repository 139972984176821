<template>
    <div>
      <el-dropdown>
        <span class="el-dropdown-link">
          <el-input v-model="search_place" :placeholder="place_selected ? place_selected.name : 'Not selected'">
            <template #prepend v-if="place_selected">
              <el-image style="width: 24px; height: 24px" :src="api + 'places/icon?type=' + place_selected.type" fit="contain" />
            </template>
            <template #append v-if="place_selected">
                <strong> @ {{ place_selected.alias }}</strong>
            </template>
          </el-input>
        </span>
        <template #dropdown>
          <el-dropdown-menu v-if="places_suggest">
            <el-dropdown-item v-for="place,i in places_suggest" :key="i" @click="select_place(place)">
              <el-image style="width: 32px; height: 32px" :src="api + 'places/icon?type=' + place.type" fit="contain" />
              @{{ place.alias }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
  
    export default {
      name: 'PlaceSelector',
      components: {
  
      },
      props: ['modelValue'],
      emits: ['update:modelValue', 'selected'],
      data: function () {
      return {
        api: process.env.VUE_APP_API,
        count: 0,
        error_pack: {},
        search_place: '',
        places_suggest: [],
        place_selected: null
      }
    },
      methods: {
        select_place(place) {
          //console.log("ACTION", place);
          this.place_selected = place;
          
          this.search_place = '';
          this.places_suggest = [];
          this.$emit('selected', place);
  
          this.$emit('update:modelValue', place.alias)
        },
        select_by_alias(alias)
        {
          if(alias)
          {
            this.places_suggest = [];
            axios.get(this.api + 'place/search/', { params: { alias: alias }, withCredentials: true })
            .then(response => {
              //this.places_suggest = response.data;
              //console.log("Reponse", response.data);
              this.place_selected = response.data
            })
            .catch(e => console.log("ERR51: " + e));          
          }
        }
      },
      watch: {
        search_place: function() {
          if(this.search_place)
          {
            console.log("LOAD...................", this.search_place)
            this.places_suggest = [];
            axios.get(this.api + 'place/search/', { params: { alias: this.search_place }, withCredentials: true })
            .then(response => {
              this.places_suggest = response.data;
            })
            .catch(e => console.log("ERR46: " + e));          
          }
        },
        modelValue: function(alias)
        {
          if(!alias)
          {
            this.place_selected = null;
          }
          else
          {
            this.select_by_alias(alias)
          }
        }
      },
      mounted()
      {
        this.select_by_alias(this.modelValue)
      }
    }
  </script>
  
  <style scoped lang="css">
  .line
  {
    text-overflow: clip ellipsis;
  
  }
  </style>